<template>
    <div class="section">
        <div class="center-section">
            <div class="form-heading">
                <h1 class="header-1">Thank you for registration!</h1>
                <p class="caption">
                    Please check your email to confirm your account
                </p>
            </div>

            <div class="resend-section">
                <p v-if="resendMessage" class="resend-message" :class="{ error: isError }">
                    {{ resendMessage }}
                </p>
                <primary-btn class="resend-btn" @click="handleResend" :disabled="isLoading">
                    {{ isLoading ? 'Sending...' : 'Resend verification email' }}
                </primary-btn>
            </div>

            <div class="login-link caption">
                <router-link to="/login" class="link">Back to Log in</router-link>
            </div>
        </div>
    </div>

</template>

<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { authAPI } from '@/services/api';
import PrimaryBtn from '@/components/PrimaryBtn.vue';

const route = useRoute();
const email = route.query.email;
const isLoading = ref(false);
const resendMessage = ref('');
const isError = ref(false);

async function handleResend() {
    if (!email) {
        resendMessage.value = 'Email address not found';
        isError.value = true;
        return;
    }

    isLoading.value = true;
    resendMessage.value = '';
    isError.value = false;

    try {
        await authAPI.resendVerification(email);
        resendMessage.value = 'Verification email has been sent!';
        isError.value = false;
    } catch (error) {
        console.error('Error resending verification:', error);
        resendMessage.value = 'Failed to send verification email. Please try again.';
        isError.value = true;
    } finally {
        isLoading.value = false;
    }
}
</script>

<style scoped>
.signup-btn-group {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 1vh;
}

.center-section {
    width: 60%;
    margin: auto;
}

.form-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 1vh;
}

.form-heading .caption {
    color: rgba(255, 255, 255, .4);
    text-align: center;
}

.form-heading img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
}

.signup-form {
    padding: 2.5vh;
    margin-bottom: 24px;
    background-color: rgba(255, 255, 255, 0.1) !important;
    border-radius: 20px;
}

.reset {
    margin-top: 1vh;
    display: flex;
    justify-content: center;
}

.form-group {
    padding: 1vh;
}

.login-link {
    text-align: center;
    color: rgba(255, 255, 255, .4);
}

.link {
    color: white;
    text-decoration: none;
}

.resend-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    text-align: center;
}

.resend-message {
    margin-bottom: 16px;
    font-size: 14px;
    color: #4CAF50;
}

.resend-btn {
    width: 50%;
}
.resend-message.error {
    color: #ff6b6b;
}
</style>