import { createRouter, createWebHistory } from "vue-router";

import { useStore } from 'vuex';
import Cookies from 'js-cookie';

import LoginLayout from "../layouts/LoginLayout.vue";
import SignupForm from "../views/Auth/SignupForm.vue";
import SignupSuccess from "../views/Auth/SignupSuccess.vue";
import SignupConfirm from "../views/Auth/SignupConfirm.vue";
import LoginForm from "../views/Auth/LoginForm.vue";
import ResetForm from "../views/Auth/ResetForm.vue";
import ResetConfirm from "../views/Auth/ResetConfirm.vue";
import ResetPassForm from "../views/Auth/ResetPassForm.vue";
import ResetSuccess from "../views/Auth/ResetSuccess.vue";

import MainLayout from "../layouts/MainLayout.vue";
import HomeView from "../views/Main/HomeView.vue";
import AnimationsView from "../views/Main/AnimationsView.vue";
import FavoritesView from "../views/Main/FavoritesView.vue";
import AnimationPreview from "../views/AnimationPreview.vue";
import NotFound from "../views/NotFound.vue";
import MinLayout from "@/layouts/MinLayout.vue";
import UploadForm from "../views/UploadForm.vue";
import PromptForm from "../views/PromptForm.vue";
import PlanContent from "../views/PlanContent.vue";
import CheckoutSummary from "../views/CheckoutSummary.vue";
import ProfileView from "@/views/ProfileView.vue";
import CancelationSub from "@/views/CancelationSub.vue";
import CancelationSubSuccess from "@/views/CancelationSubSuccess.vue";

const DEFAULT_TITLE = 'AvaCapo Platform';

const routes = [
  { path: "/", redirect: "/signup" },
  {
    path: "/signup",
    component: LoginLayout,
    children: [
      { path: "", component: SignupForm },
      { path: "success", component: SignupSuccess },
      { path: "confirm/:token", component: SignupConfirm },
    ],
  },
  {
    path: "/login",
    component: LoginLayout,
    children: [{ path: "", component: LoginForm }],
  },
  {
    path: "/resetpass",
    component: LoginLayout,
    children: [
      { path: "", component: ResetForm },
      { path: "confirm", component: ResetConfirm },
      { path: "success", component: ResetSuccess },
      { 
        path: "reset", 
        name: "ResetPassForm",
        component: ResetPassForm, 
        props: route => ({ 
          token: route.query.token
        }) 
      },
    ],
  },
  { path: "/animation", component: AnimationPreview },
  { path: "/app", redirect: "/app/home" },
  {
    path: "/app",
    component: MainLayout,
    children: [
      { path: "home", component: HomeView },
      { path: "animations", component: AnimationsView },
      { path: "favorites", component: FavoritesView },
    ],
  },
  {
    path: "/upload",
    component: MinLayout,
    children: [
      {path: "", component: UploadForm}
    ]
  },
  {
    path: "/prompt",
    component: MinLayout,
    children: [
      {path: "", component: PromptForm}
    ]
  },
  {
    path: "/plans",
    component: MinLayout,
    children: [
      {path: "", component: PlanContent}
    ]
  },
  {
    path: "/checkout",
    component: MinLayout,
    children: [
      {path: "", component: CheckoutSummary}
    ]
  },
  {
    path: "/profile",
    component: MinLayout,
    children: [
      {path: "", component: ProfileView}
    ]
  },
  {
    path: "/subcancel",
    component: MinLayout,
    children: [
      {path: "", component: CancelationSub}
    ]
  },
  {
    path: "/subcancelsuccess",
    component: MinLayout,
    children: [
      {path: "", component: CancelationSubSuccess}
    ]
  },
  { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFound },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = DEFAULT_TITLE;

  const publicPages = ['/login', '/signup', '/resetpass'];
  const authRequired = !publicPages.some((page) => to.path.startsWith(page));
  const store = useStore();
  const isLoggedIn = store.getters.isLoggedIn;

  if (to.path.startsWith('/resetpass') && 
      to.path !== '/resetpass/confirm' && 
      (!to.query.fromProfile || to.query.fromProfile !== 'true')) {
    store.commit('clearSessionId');
    Cookies.remove('sessionId');
  }

  // Allow access to reset password confirm page if coming from profile
  if (isLoggedIn && to.path === '/resetpass/confirm' && to.query.fromProfile === 'true') {
    return next();
  }

  if (isLoggedIn && publicPages.some((page) => to.path.startsWith(page))) {
    return next('/app/home');
  }

  if (authRequired && !isLoggedIn) {
    return next({
      path: '/login',
      state: { message: 'Please log in to access this page.' },
    }); 
  }

  next();
});


export default router;