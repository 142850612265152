import store from '@/store';

const API_BASE_URL = `${process.env.VUE_APP_BACKEND_URL}`;

export async function fetchWithAuthCheck(url, options = {}) {
    try {
        const response = await fetch(url, options);
        
        if (!response.ok) {
            if (response.status === 401) {
                store.dispatch('logout');
                throw new Error('Authentication failed');
            }
            
            const errorData = await response.json().catch(() => ({}));
            throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
        }

        return response;
    } catch (error) {
        console.error('API call failed:', error);
        throw error;
    }
}

// Animation related API calls
export const animationAPI = {
    async getAnimationList(sessionId) {
        return fetchWithAuthCheck(
            `${API_BASE_URL}/get-animation-list/`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'session-id': sessionId,
                }
            }
        ).then(res => res.json());
    },

    async downloadAnimation(hashedId, sessionId, extension = 'glb') {
        return fetchWithAuthCheck(
            `${API_BASE_URL}/download-animation/?hashed_id=${hashedId}&extension=${extension}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'session-id': sessionId
                }
            }
        );
    },

    async uploadVideo(formData, sessionId, onProgress) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', `${API_BASE_URL}/upload-video/`);
            xhr.setRequestHeader('session-id', sessionId);

            xhr.upload.onprogress = (event) => {
                if (event.lengthComputable && onProgress) {
                    onProgress(event.loaded, event.total);
                }
            };

            xhr.onload = () => {
                if (xhr.status === 200) {
                    resolve(JSON.parse(xhr.responseText));
                } else {
                    reject(new Error(`Upload failed: ${xhr.status}`));
                }
            };

            xhr.onerror = () => reject(new Error('Upload failed'));
            xhr.send(formData);
        });
    },

    async createPromptAnimation(promptData, sessionId) {
        return fetchWithAuthCheck(
            `${API_BASE_URL}/prompt/`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-id': sessionId
                },
                body: JSON.stringify({
                    prompt: promptData.prompt,
                    duration: promptData.duration,
                    fps: promptData.fps,
                    temperature: promptData.temperature,
                    name: promptData.name || undefined
                })
            }
        ).then(res => res.json());
    },

    async getAnimationDetails(sessionId, hashedId) {
        return fetchWithAuthCheck(
            `${API_BASE_URL}/animation_details/?hashed_id=${hashedId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'session-id': sessionId
                },
            }
        ).then(res => res.json());
    },

    async getFavoriteAnimations(sessionId) {
        return fetchWithAuthCheck(
            `${API_BASE_URL}/get-favorite-animation/`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'session-id': sessionId,
                }
            }
        ).then(res => res.json());
    },
    async toggleFavorite(sessionId, hashedId) {
        return fetchWithAuthCheck(
            `${API_BASE_URL}/favorite`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-id': sessionId
                },
                body: JSON.stringify({ hashed_id: hashedId })
            }
        ).then(res => res.json());
    },
    async changeAnimationName(sessionId, hashedId, newName) {
        return fetchWithAuthCheck(
            `${API_BASE_URL}/change_animation_name/`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-id': sessionId
                },
                body: JSON.stringify({ hashed_id: hashedId, new_name: newName })
            }
        ).then(res => res.json());
    },
    async deleteAnimation(sessionId, hashedId) {
        return fetchWithAuthCheck(
            `${API_BASE_URL}/delete_animation/?hashed_id=${hashedId}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-id': sessionId
                }
            }
        ).then(res => res.json());
    }
};

// Animation Processing API calls
export const animationProcessingAPI = {
    async healthCheck() {
        return fetch(`${API_BASE_URL}/health-check`);
    },

    async uploadVideo(formData, sessionId, onProgress) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', `${API_BASE_URL}/upload-video/`);
            xhr.setRequestHeader('session-id', sessionId);

            xhr.upload.onprogress = (event) => {
                if (event.lengthComputable && onProgress) {
                    onProgress(event.loaded, event.total);
                }
            };

            xhr.onload = () => {
                if (xhr.status === 200) {
                    resolve(JSON.parse(xhr.responseText));
                } else {
                    reject(new Error(`Upload failed: ${xhr.status}`));
                }
            };

            xhr.onerror = () => reject(new Error('Upload failed'));
            xhr.send(formData);
        });
    },

    async processPrompt(promptData, sessionId) {
        return fetchWithAuthCheck(
            `${API_BASE_URL}/prompt/`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-id': sessionId
                },
                body: JSON.stringify(promptData)
            }
        ).then(res => res.json());
    },

    async downloadAnimation(hashedId, extension, sessionId) {
        return fetchWithAuthCheck(
            `${API_BASE_URL}/download-animation/?hashed_id=${hashedId}&extension=${extension}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'session-id': sessionId
                }
            }
        );
    },

    async chooseFormat(animationId, format, sessionId) {
        return fetchWithAuthCheck(
            `${API_BASE_URL}/choose-format/`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-id': sessionId
                },
                body: JSON.stringify({
                    animation_id: animationId,
                    animation_format: format
                })
            }
        ).then(res => res.json());
    },

    async addCharacter(animationId, endAnimation = 500, sessionId) {
        return fetchWithAuthCheck(
            `${API_BASE_URL}/add-character/`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-id': sessionId
                },
                body: JSON.stringify({
                    animation_id: animationId,
                    end_animation: endAnimation
                })
            }
        ).then(res => res.json());
    },

    async checkBlenderStatus(taskId, sessionId) {
        return fetchWithAuthCheck(
            `${API_BASE_URL}/check-blender-status/`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-id': sessionId
                },
                body: JSON.stringify({ task_id: taskId })
            }
        ).then(res => res.json());
    },

    async changeAnimStatus(taskId, status) {
        return fetchWithAuthCheck(
            `${API_BASE_URL}/change-anim-status/`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ task_id: taskId, status })
            }
        ).then(res => res.json());
    }
};

// User related API calls
export const userAPI = {
    async getAnimationList(sessionId) {
        return fetchWithAuthCheck(
            `${API_BASE_URL}/get-animation-list/`,
            {
                method: 'GET',
                headers: {
                    'session-id': sessionId
                }
            }
        ).then(res => res.json());
    },

    async getFavoriteAnimations(sessionId) {
        return fetchWithAuthCheck(
            `${API_BASE_URL}/get-favorite-animation/`,
            {
                method: 'GET',
                headers: {
                    'session-id': sessionId
                }
            }
        ).then(res => res.json());
    },

    async changeAnimationName(sessionId, hashedId, newName) {
        return fetchWithAuthCheck(
            `${API_BASE_URL}/change_animation_name/`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-id': sessionId
                },
                body: JSON.stringify({ hashed_id: hashedId, new_name: newName })
            }
        ).then(res => res.json());
    },

    async getAnimationDetails(sessionId, hashedId) {
        return fetchWithAuthCheck(
            `${API_BASE_URL}/animation_details/?hashed_id=${hashedId}`,
            {
                method: 'GET',
                headers: {
                    'session-id': sessionId
                }
            }
        ).then(res => res.json());
    },

    async getUserDetails(sessionId) {
        return fetchWithAuthCheck(
            `${API_BASE_URL}/user_details/`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'session-id': sessionId
                }
            }
        ).then(res => res.json());
    },

    async getPlanDetails() {
        return fetch(
            `${API_BASE_URL}/plan_details/`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(res => res.json())
          .then(data => {
              if (!data) {
                  throw new Error('Invalid response format for plan details');
              }
              return data;
          });
    },

    async getRemainingCredits(sessionId) {
        return fetchWithAuthCheck(
            `${API_BASE_URL}/remaining_credits/`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'session-id': sessionId
                }
            }
        ).then(res => res.json())
          .then(data => {
              if (!data || typeof data.remaining_time === 'undefined') {
                  throw new Error('Invalid response format');
              }
              return data;
          });
    },

    async changeUserName(sessionId, newName) {
        return fetchWithAuthCheck(
            `${API_BASE_URL}/change_user_name/`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-id': sessionId
                },
                body: JSON.stringify({ new_name: newName })
            }
        ).then(res => res.json());
    },

    async activatePromocode(sessionId, promocode) {
        return fetchWithAuthCheck(
            `${API_BASE_URL}/promocode_activation/`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-id': sessionId
                },
                body: JSON.stringify({ promocode })
            }
        ).then(res => res.json());
    }
};

// Subscription API calls
export const subscriptionAPI = {
    async cancelSubscription(subscriptionId, reason, sessionId) {
        return fetchWithAuthCheck(
            `${API_BASE_URL}/cancel_subscription/?subsription_id=${subscriptionId}&reason=${reason}`,
            {
                method: 'POST',
                headers: {
                    'session-id': sessionId
                }
            }
        ).then(res => res.json());
    },

    async updateSubscription(subscriptionId, data, sessionId) {
        return fetchWithAuthCheck(
            `${API_BASE_URL}/update_subscription/?subsription_id=${subscriptionId}&data=${data}`,
            {
                method: 'POST',
                headers: {
                    'session-id': sessionId
                }
            }
        ).then(res => res.json());
    }
};

// Auth related API calls
export const authAPI = {
    async register(userData) {
        const response = await fetch(`${API_BASE_URL}/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userData)
        });

        if (!response.ok) {
            throw new Error('Registration failed');
        }

        return response.json();
    },

    async login(credentials) {
        const response = await fetch(`${API_BASE_URL}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        });

        if (!response.ok) {
            throw new Error('Login failed');
        }

        return response.json();
    },

    async confirmEmail(token) {
        const response = await fetch(`${API_BASE_URL}/confirm-email/${token}/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw response;
        }

        return response;
    },

    async resendVerification(email) {
        return fetch(`${API_BASE_URL}/resend-verification/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email })
        });
    },

    async resetPasswordEmail(email) {
        const response = await fetch(`${API_BASE_URL}/reset-password-email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email })
        });

        if (!response.ok) {
            const error = new Error('Failed to send reset password email');
            error.response = response;
            throw error;
        }

        return response.json();
    },

    async resetPassword(resetData) {
        const response = await fetch(`${API_BASE_URL}/reset-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(resetData)
        });

        if (!response.ok) {
            const error = new Error('Failed to reset password');
            error.response = response;
            throw error;
        }

        return response.json();
    },

    async logout(sessionId) {
        return fetchWithAuthCheck(
            `${API_BASE_URL}/logout/`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-id': sessionId
                }
            }
        ).then(res => res.json());
    }
};
