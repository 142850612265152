<template>
  <div class="section">
    <div class="center-section">
      <div class="form-heading">
        <h1 class="header-1">Check your email</h1>
        <p class="caption">
          We've sent a password reset link to your email address. Please check your inbox
          and follow the instructions.
        </p>
      </div>

      <div class="login-link caption">
        <router-link :to="fromProfile ? '/profile' : '/login'" class="link">
          {{ fromProfile ? "Back to Profile" : "Back to Login" }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";

const route = useRoute();
const fromProfile = route.query.fromProfile === "true";
</script>

<style scoped>
.section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.center-section {
  width: 35%;
  min-width: 300px;
}

.form-heading {
  text-align: center;
  margin-bottom: 24px;
}

.form-heading h1 {
  margin-bottom: 12px;
}

.form-heading .caption {
  color: rgba(255, 255, 255, 0.4);
  margin: 0 auto;
  max-width: 400px;
}

.login-link {
  text-align: center;
  color: rgba(255, 255, 255, 0.4);
}

.link {
  color: white;
  text-decoration: none;
  transition: opacity 0.2s;
}

.link:hover {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .center-section {
    width: 90%;
  }
}
</style>
