<template>
  <div class="credits-bar-container">
    <div class="credits-header">
      <span class="credits-label caption">Credits left</span>
      <span class="credits-value">{{ formattedCredits }}</span>
    </div>
    <div class="progress-bar">
      <div class="progress-fill" :style="{ width: creditPercentage + '%' }"></div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { subscriptionPlanMap, plans } from "@/constants/plans";

const store = useStore();

onMounted(() => {
  store.dispatch("fetchRemainingCredits");
  store.dispatch("fetchUserDetails");
});

const credits = computed(() => store.getters.getRemainingCredits);

const maxCredits = computed(() => {
  const userDetails = store.getters.getUserDetails;
  if (!userDetails) return 300;

  const currentPlanName = userDetails.current_subscription_plan || "Freemium";
  const mappedPlanKey = subscriptionPlanMap[currentPlanName];
  const planDetails = plans[mappedPlanKey || "Freemium"];

  return planDetails?.creditsByDefault || 300;
});

const formattedCredits = computed(() => {
  return Number(credits.value).toFixed(2);
});

const creditPercentage = computed(() => {
  return maxCredits.value > 0 ? (credits.value / maxCredits.value) * 100 : 0;
});
</script>

<style scoped>
.credits-bar-container {
  width: 100%;
}

.credits-header {
  display: flex;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 1rem;
}

.credits-label {
  color: rgba(255, 255, 255, 0.6);
}

.credits-value {
  color: #fff;
}

.progress-bar {
  width: 100%;
  height: 0.2rem;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background: linear-gradient(99.6deg, #20ad8b 11.39%, #ace41b 86.3%);
  border-radius: 3px;
  transition: width 0.3s ease-in-out;
}
</style>
