<template>
    <div class="section">
        <div class="center-section">
            <div class="form-heading">
                <h1 class="header-1">Create new password</h1>
                <p class="caption">
                    Make sure your new password is secure
                </p>
            </div>
           
            <div class="signup-form">
                <div v-if="error" class="error-message">
                    <BaseIcon iconName="error" color="#ff6b6b" />
                    {{ error }}
                </div>

                <div v-if="successMessage" class="success-message">
                    <BaseIcon iconName="check" color="#20AD8B" />
                    {{ successMessage }}
                </div>

                <form @submit.prevent="handleSubmit">
                    <div class="password-section">
                        <base-input 
                            class="form-group" 
                            type="password" 
                            placeholder="New password" 
                            required 
                            icon="lock"
                            v-model="newPassword"
                            :error="passwordError"
                        />
                        
                        <div class="password-strength" v-if="newPassword">
                            <div class="strength-item" :class="{ active: hasMinLength }">
                                <BaseIcon :iconName="hasMinLength ? 'check' : 'circle'" />
                                At least 8 characters
                            </div>
                            <div class="strength-item" :class="{ active: hasUpperCase }">
                                <BaseIcon :iconName="hasUpperCase ? 'check' : 'circle'" />
                                One uppercase letter
                            </div>
                            <div class="strength-item" :class="{ active: hasLowerCase }">
                                <BaseIcon :iconName="hasLowerCase ? 'check' : 'circle'" />
                                One lowercase letter
                            </div>
                            <div class="strength-item" :class="{ active: hasNumber }">
                                <BaseIcon :iconName="hasNumber ? 'check' : 'circle'" />
                                One number
                            </div>
                        </div>

                        <base-input 
                            class="form-group" 
                            type="password" 
                            placeholder="Confirm password" 
                            required 
                            icon="lock"
                            v-model="confirmPassword"
                            :error="confirmError"
                        />
                    </div>

                    <primary-btn :disabled="!isValid || isLoading">
                        {{ isLoading ? 'Updating Password...' : 'Update Password' }}
                    </primary-btn>
                </form>
            </div>

            <div class="login-link caption">
                <router-link to="/login" class="link">
                    Back to Login
                </router-link>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import BaseInput from '@/components/BaseInput.vue';
import PrimaryBtn from '@/components/PrimaryBtn.vue';
import BaseIcon from '@/components/BaseIcon.vue';
import { authAPI } from '@/services/api';

const props = defineProps({
    token: {
        type: String,
        required: true
    }
});

const router = useRouter();

const newPassword = ref('');
const confirmPassword = ref('');
const error = ref('');
const successMessage = ref('');
const isLoading = ref(false);

// Password strength indicators
const hasMinLength = computed(() => newPassword.value.length >= 8);
const hasUpperCase = computed(() => /[A-Z]/.test(newPassword.value));
const hasLowerCase = computed(() => /[a-z]/.test(newPassword.value));
const hasNumber = computed(() => /[0-9]/.test(newPassword.value));

onMounted(() => {
    if (!props.token) {
        error.value = 'Invalid reset link. Please request a new one.';
        setTimeout(() => router.push('/resetpass'), 2000);
    }
});

const passwordError = computed(() => {
    if (!newPassword.value) return '';
    const errors = [];
    
    if (newPassword.value.length < 8) {
        errors.push('at least 8 characters');
    }
    if (!/[A-Z]/.test(newPassword.value)) {
        errors.push('one uppercase letter');
    }
    if (!/[a-z]/.test(newPassword.value)) {
        errors.push('one lowercase letter');
    }
    if (!/[0-9]/.test(newPassword.value)) {
        errors.push('one number');
    }

    if (errors.length > 0) {
        return `Password must contain ${errors.join(', ')}`;
    }
    return '';
});

const confirmError = computed(() => {
    if (!confirmPassword.value) return '';
    if (confirmPassword.value !== newPassword.value) {
        return 'The passwords you entered don\'t match';
    }
    return '';
});

const isValid = computed(() => {
    return newPassword.value.length >= 8 &&
           /[A-Z]/.test(newPassword.value) &&
           /[a-z]/.test(newPassword.value) &&
           /[0-9]/.test(newPassword.value) &&
           confirmPassword.value === newPassword.value;
});

async function handleSubmit() {
    if (!isValid.value) {
        error.value = 'Please ensure your password meets all requirements';
        return;
    }

    isLoading.value = true;
    error.value = '';
    successMessage.value = '';

    try {
        await authAPI.resetPassword({
            secret_token: props.token,
            new_password: newPassword.value,
            confirm_password: confirmPassword.value
        });

        successMessage.value = 'Password updated successfully! Redirecting to login...';
        
        setTimeout(() => {
            router.push('/resetpass/success');
        }, 1500);
    } catch (err) {
        console.error('Password reset error:', err);
        if (err.response?.status === 406){
            console.log(err);
            error.value = 'This reset link has expired. Please request a new one.';
        } else if (err.response?.status === 400) {
            error.value = 'Please ensure your password meets all security requirements.';
        } else {
            error.value = 'We\'re having trouble updating your password. Please try again.';
        }
    } finally {
        isLoading.value = false;
    }
}
</script>

<style scoped>
.section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.center-section {
    width: 35%;
    min-width: 300px;
}

.form-heading {
    text-align: center;
    margin-bottom: 24px;
}

.form-heading h1 {
    margin-bottom: 12px;
}

.form-heading .caption {
    color: rgba(255, 255, 255, .4);
}

.signup-form {
    padding: 24px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    margin-bottom: 24px;
}

.form-group {
    margin-bottom: 16px;
}

.error-message {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: rgba(255, 107, 107, 0.1);
    color: #ff6b6b;
    text-align: center;
    margin-bottom: 16px;
    padding: 12px;
    border-radius: 8px;
}

.success-message {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: rgba(32, 173, 139, 0.1);
    color: #20AD8B;
    text-align: center;
    margin-bottom: 16px;
    padding: 12px;
    border-radius: 8px;
}

.password-strength {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    padding: 12px;
    margin: 8px 0;
}

.strength-item {
    display: flex;
    align-items: center;
    gap: 8px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    margin: 4px 0;
}

.strength-item.active {
    color: #20AD8B;
}

.login-link {
    text-align: center;
    color: rgba(255, 255, 255, .4);
}

.link {
    color: white;
    text-decoration: none;
    transition: opacity 0.2s;
}

.link:hover {
    opacity: 0.8;
}

@media (max-width: 768px) {
    .center-section {
        width: 90%;
    }
}
</style>