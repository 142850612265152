<template>
  <div class="checkout-summary-wrapper">
    <div class="plan-details">
      <h1 class="header-2">Plan Summary</h1>
      <p class="body-text gray-text ">{{ plan?.title }}</p>
      <p class="header-2">{{ plan?.price }}$/month</p>
      <p v-if="isAnnual" class="caption gray-text annual-text">
        billed annually as {{ annualPrice }}$
      </p>
      <p class="caption gray-text ">{{ plan?.description }}</p>
      <ul class="caption gray-text">
        <li v-for="(feature, index) in plan?.features" :key="index">
          {{ feature }}
        </li>
      </ul>
    </div>
    <div class="payment-terms">
      <div id="paypal-button-container"></div>
    </div>

    <div v-if="showPopup" class="status-popup-overlay" @click="closePopup">
      <div class="status-popup" @click.stop>
        <div class="status-icon" :class="{ success: !isError }">
          <BaseIcon :iconName="isError ? 'error' : 'check'" :color="isError ? '#ff6b6b' : '#20AD8B'" />
        </div>
        <h3>{{ popupTitle }}</h3>
        <p>{{ popupMessage }}</p>
        <button class="close-btn" @click="closePopup">Close</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { onMounted, computed, ref } from "vue";
import { plans } from "@/constants/plans";
import BaseIcon from '@/components/BaseIcon.vue';

const router = useRouter();
const store = useStore();
const selectedPlan = store.state.selectedPlan;
const isAnnual = computed(() => store.state.isAnnual);
const userDetails = computed(() => store.state.userDetails);

const showPopup = ref(false);
const isError = ref(false);
const popupTitle = ref('');
const popupMessage = ref('');

const plan = computed(() => {
  const planDetails = plans[selectedPlan.title];
  const currentPlanName = userDetails.value?.current_subscription_plan;
  
  return {
    ...planDetails,
    price: isAnnual.value ? planDetails.priceAnnual : planDetails.priceMonthly,
    displayName: planDetails.displayNames[isAnnual.value ? 'annual' : 'monthly'],
    currentPlan: currentPlanName || 'Freemium'
  };
});

const annualPrice = computed(() => {
  return plan.value?.price ? plan.value.price * 12 : 0;
});

function showStatusPopup(success, title, message) {
  isError.value = !success;
  popupTitle.value = title;
  popupMessage.value = message;
  showPopup.value = true;
}

function closePopup() {
  showPopup.value = false;
  if (!isError.value) {
    store.dispatch('fetchUserDetails')
      .then(() => {
        router.push('/app/home');
      })
      .catch(error => {
        console.error('Failed to fetch updated user details:', error);
        router.push('/app/home');
      });
  }
}

onMounted(() => {
  if (window.paypal) {
    renderPayPalButton();
  }
});

function renderPayPalButton() {
  const planId = plan.value.paypalPlanId[isAnnual.value ? 'annual' : 'monthly'];
  const userId = userDetails.value?.user_id;
  
  if (!planId) {
    showStatusPopup(false, 'Error', 'No valid plan ID found for the selected plan');
    return;
  }

  if (!userId) {
    showStatusPopup(false, 'Error', 'No user ID found');
    return;
  }

  window.paypal
    .Buttons({
      createSubscription(data, actions) {
        return actions.subscription.create({
          plan_id: planId,
          custom_id: userId.toString(),
        });
      },
      // eslint-disable-next-line no-unused-vars
      onApprove(data) {
        store.dispatch('fetchUserDetails')
          .then(() => {
            showStatusPopup(
              true,
              'Subscription Successful!',
              'Your subscription has been activated. Enjoy the AvaCapo platform! You will be redirected to the home page.'
            );
          })
          .catch(error => {
            console.error('Failed to fetch updated user details:', error);
            showStatusPopup(
              true,
              'Subscription Successful!',
              'Your subscription has been activated, but we had trouble updating your details. Please refresh the page.'
            );
          });
      },
      onError(err) {
        console.error("PayPal Error:", err);
        showStatusPopup(
          false,
          'Payment Failed',
          'There was an error processing your payment. Please try again.'
        );
      },
      style: {
        color: 'black',
      }
    })
    .render("#paypal-button-container");
}
</script>

<style scoped>
.checkout-summary-wrapper {
  display: flex;
  background-color: rgba(255, 255, 255, 0.1); 
  border-radius: 2.5rem;
  position: relative;
}

.payment-terms, 
.plan-details {
  padding: 2.5rem;
  width: 50%;
}

.payment-terms {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 2.5rem;
}

/* Status Popup Styles */
.status-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.status-popup {
  background: rgba(42, 41, 42, 1);
  padding: 2rem;
  border-radius: 16px;
  text-align: center;
  max-width: 400px;
  width: 90%;
}

.status-icon {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}

.status-icon.success {
  color: #20AD8B;
}

.status-popup h3 {
  margin: 0 0 1rem 0;
  color: #fff;
}

.status-popup p {
  margin: 0 0 1.5rem 0;
  color: rgba(255, 255, 255, 0.7);
}

.close-btn {
  padding: 8px 24px;
  border-radius: 8px;
  background: linear-gradient(135deg, #20AD8B 0%, #ACE41B 100%);
  color: white;
  border: none;
  cursor: pointer;
  transition: opacity 0.3s;
}

.close-btn:hover {
  opacity: 0.9;
}

.annual-text {
  margin-top: -8px;
  margin-bottom: 16px;
}
</style>