<template>
  <div class="loader-wrapper">
    <div class="header-2 loader-description" v-if="label">
      {{ label }}
    </div>

    <div v-if="isProcessing" class="caption loader-description">
      Your animation is being processed
    </div>

    <div v-if="!isProcessing" class="caption loader-description">
      Your animation is being downloaded
    </div>

    <template v-if="isProcessing">
      <div class="progress-placeholder">
        <div class="spinner">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="progress-placeholder">
        <svg class="progress-circle" width="100" height="100">
          <defs>
            <radialGradient id="progress-gradient" cx="50%" cy="50%" r="50%">
              <stop offset="26%" stop-color="#20AD8B" />
              <stop offset="76%" stop-color="#ACE41B" />
            </radialGradient>
          </defs>
          <g transform="rotate(-90, 50, 50)">
            <circle class="progress-circle-bg" cx="50" cy="50" r="45" fill="transparent" stroke="#eee"
              stroke-width="10" />
            <circle class="progress-circle-fg" cx="50" cy="50" r="45" fill="transparent"
              stroke="url(#progress-gradient)" stroke-width="10" :stroke-dasharray="circumference"
              :stroke-dashoffset="circumference - (progress / 100) * circumference" stroke-linecap="round" />
          </g>
        </svg>
      </div>

      <div v-if="showSize" class="progress-label">
        {{ formattedLoadedSize }} / {{ formattedTotalSize }} ({{ Math.round(progress) }}%)
      </div>
    </template>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  progress: {
    type: Number,
    required: true,
    default: 0
  },
  label: {
    type: String,
    default: ''
  },
  isProcessing: {
    type: Boolean,
    default: false
  },
  loadedSize: {
    type: Number,
    default: 0
  },
  totalSize: {
    type: Number,
    default: 0
  },
  showSize: {
    type: Boolean,
    default: false
  }
});

const loaderRadius = 45;
const circumference = computed(() => 2 * Math.PI * loaderRadius);

const formattedLoadedSize = computed(() => formatSize(props.loadedSize));
const formattedTotalSize = computed(() => formatSize(props.totalSize));

function formatSize(size) {
  if (size < 1024) return `${size} B`;
  const kb = size / 1024;
  if (kb < 1024) return `${kb.toFixed(1)} KB`;
  return `${(kb / 1024).toFixed(1)} MB`;
}
</script>

<style scoped>
.loader-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.caption {
  color: rgba(255, 255, 255, .4);
}

.loader-description {
  margin-bottom: 10px;
}

.header-2 {
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.progress-placeholder {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 12px;
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-clip: padding-box;
  background-position: center;
  border: 1rem solid rgba(255, 255, 255, 0.1);
}

.progress-circle-bg,
.progress-circle-fg {
  transition: stroke-dashoffset 0.3s linear;
}

.progress-label {
  margin-top: 1rem;
  color: rgba(255, 255, 255, 0.7);
}

/* New spinning dots styles */
.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: linear-gradient(135deg, #20AD8B 0%, #ACE41B 100%);
  animation: spin 1.5s infinite;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

.dot:nth-child(4) {
  animation-delay: 0.6s;
}

@keyframes spin {

  0%,
  100% {
    transform: scale(0.3);
    opacity: 0.3;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>