<template>
  <div
    v-if="visible"
    class="context-menu fixed-container"
    :style="styleObject"
    ref="contextMenu"
  >
    <ul>
      <li @click="seeProfile">
        <BaseIcon iconName="settings" class="item-main-icon" :width="25" :height="25">
        </BaseIcon>
        Profile settings
      </li>

      <li @click="openSupportPopup">
        <BaseIcon iconName="help" class="item-main-icon" :width="25" :height="25">
        </BaseIcon>
        Support & Feedback
      </li>

      <li class="locked">
        <BaseIcon
          iconName="credit"
          class="item-main-icon"
          :width="25"
          :height="25"
          color="rgba(255,255,255,0.4)"
        >
        </BaseIcon>
        Buy credits
        <BaseIcon
          iconName="lock"
          class="lock-icon"
          :width="25"
          :height="25"
          color="rgba(255,255,255,0.4)"
        >
        </BaseIcon>
      </li>

      <li @click="seePlans">
        <BaseIcon iconName="barGroup" class="item-main-icon" :width="25" :height="25">
        </BaseIcon>
        See plans
      </li>

      <li @click="logout" class="logout-item">
        <BaseIcon iconName="logout" class="item-main-icon" :width="25" :height="25">
        </BaseIcon>
        Log out
      </li>
    </ul>
  </div>

  <SupportPopup :show="showSupportPopup" @close="showSupportPopup = false" />
</template>

<script setup>
import { ref, reactive, onMounted, onUnmounted, nextTick } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import BaseIcon from "@/components/BaseIcon.vue";
import SupportPopup from "./SupportPopup.vue";

const store = useStore();
const router = useRouter();
const visible = ref(false);
const contextMenu = ref(null);
const showSupportPopup = ref(false);

const styleObject = reactive({
  position: "fixed",
  zIndex: 500,
  visibility: "hidden",
});

function showMenu(event) {
  event.stopPropagation();
  const elementRect = event.currentTarget.getBoundingClientRect();
  const menuWidth = 250;

  // Calculate the initial left position
  let left = elementRect.right - menuWidth;
  if (left < 0) {
    left = 0;
  } else if (left + menuWidth > window.innerWidth) {
    left = window.innerWidth - menuWidth;
  }

  visible.value = true;
  styleObject.visibility = "hidden"; // Initially hide to prevent flickering

  nextTick(() => {
    if (!contextMenu.value) {
      console.error("Context menu element is not available.");
      return;
    }

    const menuHeight = contextMenu.value.offsetHeight;

    let bottom = window.innerHeight - elementRect.top;

    if (bottom + menuHeight > window.innerHeight) {
      bottom = window.innerHeight - elementRect.bottom;
    }

    styleObject.left = `${left}px`;
    styleObject.bottom = `${bottom}px`;
    styleObject.visibility = "visible";
  });
}

function hideMenu() {
  visible.value = false;
}

function logout() {
  store.dispatch("logout");
}

function seePlans() {
  router.push("/plans");
}

function seeProfile() {
  router.push("/profile");
}

function openSupportPopup() {
  showSupportPopup.value = true;
  visible.value = false;
}

function handleClickOutside(event) {
  if (visible.value && contextMenu.value && !contextMenu.value.contains(event.target)) {
    hideMenu();
  }
}

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
});

defineExpose({
  showMenu,
  hideMenu,
});
</script>

<style scoped>
.context-menu {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  width: 250px;
  position: fixed;
}

.context-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.context-menu li {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.item-main-icon {
  margin-right: 10px;
}

.lock-icon {
  margin-left: auto;
}

.logout-item {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.locked {
  color: rgba(255, 255, 255, 0.4);
  cursor: not-allowed !important;
}
</style>
