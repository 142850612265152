<template>
    <div 
        class="reset-router-link menu-item" 
        :class="cellClasses"
        @mouseenter="toggleHover(true)" 
        @mouseleave="toggleHover(false)"
        @mousedown="togglePress(true)" 
        @mouseup="togglePress(false)"
        @click="navigate">

        <BaseIcon v-if="iconName" :iconName="iconName" :color="iconColor" class="cell-icon" />
        <slot></slot>
     
        <BaseIcon v-if="locked" class="lock-icon" iconName="lock" :color="iconColor" :width="14" :height="14"/>
    </div>
</template>

<script setup>
import BaseIcon from '@/components/BaseIcon.vue';
import { defineProps, ref, computed, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';

const props = defineProps({
    iconName: String,
    locked: {
        type: Boolean,
        default: false
    },
    to: {
        type: String,
        default: '/app/home'
    }
});

const router = useRouter();
const route = useRoute();
const isHovered = ref(false);
const isPressed = ref(false);
const isActiveRef = ref(false);

const toggleHover = (value) => isHovered.value = value;
const togglePress = (value) => isPressed.value = value;

const updateActiveState = () => {
    isActiveRef.value = route.path === props.to;
};

watch(() => route.path, updateActiveState);

onMounted(updateActiveState);

const cellClasses = computed(() => {
    let baseClass = '';

    if (isActiveRef.value) {
        baseClass = 'router-link-active';
    } else {
        if (isHovered.value) baseClass += ' hovered-button';
        if (isPressed.value) baseClass += ' pressed-button';
    }

    if (props.locked) {
        baseClass += ' locked';
    }

    return baseClass;
});

const iconColor = computed(() => {
    if (isActiveRef.value) {
        return 'rgba(255, 255, 255, 1)';
    }
    return isHovered.value ? 'rgba(255, 255, 255, .7)' : 'rgba(255, 255, 255, .4)';
});

const navigate = () => {
    if (!props.locked) {
        router.push(props.to);
    }
};
</script>

<style scoped>
.menu-item {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 15px 6px;
    border-radius: 5px;
    color: rgba(255, 255, 255, 0.4);
    cursor: pointer;
}

.cell-icon {
    padding-right: 8px;
}

.hovered-button {
    color: rgba(255, 255, 255, .7) !important;
}

.pressed-button {
    background-color: rgba(255, 255, 255, 0.1) !important;
}

.reset-router-link {
    text-decoration: none;
}

.router-link-active {
    color: rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, 0.1);
}

.lock-icon {
    margin-left: auto;
}

.locked {
  cursor: not-allowed !important;
}
</style>
