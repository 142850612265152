<template>
    <div class="section">
        <div class="center-section">
            <div class="form-heading">
                <h1 class="header-1">Hello</h1>
                <img src="@/assets/fingers.png" alt="Logo" />
            </div>

            <div class="signup-form">
                <div v-if="errorMessage" class="error-message">
                    {{ errorMessage }}
                    <router-link v-if="errorDetails" to="/login" class="text-link">
                        {{ errorDetails }}
                    </router-link>
                </div>

                <div v-if="successMessage" class="success-message">
                    {{ successMessage }}
                </div>

                <p class="form-description">
                    Join our community and start creating amazing animations!
                </p>

                <form @submit.prevent="handleSubmit">
                    <base-input 
                        class="form-group" 
                        type="text" 
                        placeholder="Your full name" 
                        required 
                        icon="person" 
                        v-model="name"
                        :error="nameError"
                    >
                        <template #hint>
                            <span class="input-hint">This is how we'll address you</span>
                        </template>
                    </base-input>

                    <base-input 
                        class="form-group" 
                        type="email" 
                        placeholder="Your email address" 
                        required 
                        icon="envelop"
                        v-model="email" 
                        :error="emailError"
                    >
                        <template #hint>
                            <span class="input-hint">We'll send a verification link to this email</span>
                        </template>
                    </base-input>

                    <div class="password-section">
                        <base-input 
                            class="form-group" 
                            type="password" 
                            placeholder="Create password" 
                            required 
                            icon="lock"
                            v-model="password" 
                            :error="passwordError"
                        />
                        
                        <div class="password-strength" v-if="password">
                            <div class="strength-item" :class="{ active: hasMinLength }">
                                <BaseIcon iconName="check" v-if="hasMinLength" />
                                <BaseIcon iconName="circle" v-else />
                                At least 8 characters
                            </div>
                            <div class="strength-item" :class="{ active: hasUpperCase }">
                                <BaseIcon iconName="check" v-if="hasUpperCase" />
                                <BaseIcon iconName="circle" v-else />
                                One uppercase letter
                            </div>
                            <div class="strength-item" :class="{ active: hasLowerCase }">
                                <BaseIcon iconName="check" v-if="hasLowerCase" />
                                <BaseIcon iconName="circle" v-else />
                                One lowercase letter
                            </div>
                            <div class="strength-item" :class="{ active: hasNumber }">
                                <BaseIcon iconName="check" v-if="hasNumber" />
                                <BaseIcon iconName="circle" v-else />
                                One number
                            </div>
                        </div>

                        <base-input 
                            class="form-group" 
                            type="password" 
                            placeholder="Confirm password" 
                            required 
                            icon="lock"
                            v-model="confirmPassword" 
                            :error="confirmError"
                        />
                    </div>

                    <primary-btn 
                        :disabled="!isValid" 
                        :loading="isLoading"
                    >
                        {{ isLoading ? 'Creating Account...' : 'Create Account' }}
                    </primary-btn>
                </form>
            </div>

            <div class="login-link caption">
                Already have an account? <router-link to="/login" class="link">Log In</router-link>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';

import BaseInput from '@/components/BaseInput.vue';
import PrimaryBtn from '@/components/PrimaryBtn.vue';

const name = ref('');
const email = ref('');
const password = ref('');
const confirmPassword = ref('');
const errorMessage = ref('');
const errorDetails = ref('');
const successMessage = ref('');

const router = useRouter();

const isLoading = ref(false);

// Password strength indicators
const hasMinLength = computed(() => password.value.length >= 8);
const hasUpperCase = computed(() => /[A-Z]/.test(password.value));
const hasLowerCase = computed(() => /[a-z]/.test(password.value));
const hasNumber = computed(() => /[0-9]/.test(password.value));

const nameError = computed(() => {
    if (!name.value.trim()) return '';
    if (name.value.trim().length < 2) return 'Please enter your full name';
    return '';
});

const emailError = computed(() => {
    if (!email.value.trim()) return '';
    if (!email.value.includes('@') || !email.value.includes('.')) {
        return 'Please enter a valid email address (e.g., john@example.com)';
    }
    return '';
});

const passwordError = computed(() => {
    if (!password.value) return '';
    if (password.value.length < 8) return 'Password must be at least 8 characters';
    return '';
});

const confirmError = computed(() => {
    if (!confirmPassword.value) return '';
    if (confirmPassword.value !== password.value) return 'Passwords do not match';
    return '';
});

const isValid = computed(() => {
    return name.value.trim() !== '' &&
        email.value.trim() !== '' &&
        email.value.includes('@') &&
        password.value.length >= 8 &&
        confirmPassword.value === password.value;
});

async function handleSubmit() {
    if (!isValid.value) {
        errorMessage.value = 'Please complete all fields correctly';
        return;
    }

    isLoading.value = true;
    errorMessage.value = '';
    errorDetails.value = '';
    successMessage.value = '';

    const url = `${process.env.VUE_APP_BACKEND_URL}/register`;
    const requestBody = {
        name: name.value,
        email: email.value,
        password: password.value
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        });

        if (!response.ok) {
            const result = await response.json();
            
            if (result.detail?.toLowerCase().includes('email')) {
                errorMessage.value = 'This email address appears to be invalid. Please check and try again.';
            } else if (result.detail?.toLowerCase().includes('exists')) {
                errorMessage.value = 'This email is already registered. You can try to ';
                errorDetails.value = 'log in.';
            } else {
                errorMessage.value = 'We encountered an issue creating your account. Please try again.';
            }
            return;
        }

        successMessage.value = 'Account created successfully! Redirecting to verification page...';
        
        setTimeout(() => {
            router.push({
                path: '/signup/success',
                query: { email: email.value }
            });
        }, 1500);

    } catch (error) {
        console.error('Error during registration:', error);
        errorMessage.value = 'We\'re having trouble connecting to our servers. Please try again in a moment.';
    } finally {
        isLoading.value = false;
    }
}
</script>

<style scoped>
.signup-btn-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
}

.sign-btn {
    width: 49%;
}

.center-section {
    width: 30%;
    margin: auto;
}

.form-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.form-heading h1 {
    margin-bottom: 12px;
}

.signup-form {
    padding: 2.5vh;
    margin-bottom: 24px;
    background-color: rgba(255, 255, 255, 0.1) !important;
    border-radius: 20px;
}

.form-group {
    padding: 1vh;
}

.login-link {
    text-align: center;
    color: rgba(255, 255, 255, .4);
}

.link {
    color: white;
    text-decoration: none;
}

.error-message {
    background-color: rgba(255, 107, 107, 0.1);
    color: #ff6b6b;
    text-align: center;
    margin-bottom: 1vh;
    padding: 8px;
    border-radius: 4px;
}

.success-message {
    background-color: rgba(32, 173, 139, 0.1);
    color: #20AD8B;
    text-align: center;
    margin-bottom: 1vh;
    padding: 8px;
    border-radius: 4px;
}

.form-description {
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 24px;
}

.input-hint {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
    margin-top: 4px;
}

.password-section {
    margin-bottom: 16px;
}

.password-strength {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    padding: 12px;
    margin: 8px 0;
}

.strength-item {
    display: flex;
    align-items: center;
    gap: 8px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    margin: 4px 0;
}

.strength-item.active {
    color: #20AD8B;
}

.text-link {
    color: #20AD8B;
    text-decoration: none;
    font-weight: 500;
    transition: opacity 0.2s;
}

.text-link:hover {
    opacity: 0.8;
}

@media (max-width: 1000px) {
    .center-section {
        width: 80%;
    }

    .signup-form {
        padding: 2vh;
    }

    .form-group {
        padding: 0.5vh;
    }
}
</style>