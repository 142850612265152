<template>
  <div class="plan-heading">
    <div class="header-1">Choose your plan</div>

    <div class="toggle-container">
      <button
        class="toggle-option"
        :class="{ active: !isAnnual }"
        @click="setAnnual(false)"
      >
        Monthly
      </button>

      <button
        class="toggle-option"
        :class="{ active: isAnnual }"
        @click="setAnnual(true)"
      >
        Annual
      </button>
    </div>
  </div>

  <div class="plan-content">
    <PlanCard
      v-for="(plan, index) in plansData"
      :key="index"
      class="plan-card"
      :planTitle="plan.title"
      :planDescription="plan.description"
      :priceMonthly="plan.priceMonthly"
      :priceAnnual="plan.priceAnnual"
      :features="plan.features"
      :isAnnual="isAnnual"
      :btnLabel="getBtnLabel(plan)"
      :isActive="isActivePlan(plan.paypalPlanId)"
      @selectPlan="redirectToCheckout"
    />
  </div>

  <div class="plan-content-description description gray-text">
    Credits included in subscriptions do not carry over from month to month<br />1 credit
    is about 1 seconds of animation for basic MoCap and animation generation
  </div>
</template>

<script setup>
import PlanCard from "@/components/PlanCard.vue";
import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { plans } from "@/constants/plans";

const router = useRouter();
const store = useStore();

const isAnnual = ref(false);
const isLoading = ref(true);
const apiPlans = ref(null);

const currentPlan = computed(() => store.state.activePlan || "Freemium");

onMounted(async () => {
  isLoading.value = true;
  try {
    await Promise.all([
      store.dispatch("fetchUserDetails"),
      store.dispatch("fetchPlanDetails"),
    ]);

    const planDetailsFromAPI = store.getters.getPlanDetails;
    if (planDetailsFromAPI) {
      apiPlans.value = planDetailsFromAPI;
      updatePlansData();
    } else {
      // Fallback to local plans if API fails
      console.log("Using local plans as fallback");
    }
  } catch (error) {
    console.error("Failed to fetch data:", error);
  } finally {
    isLoading.value = false;
  }
});

function isActivePlan(planPaypalId) {
  return currentPlan.value === planPaypalId[isAnnual.value ? "annual" : "monthly"];
}

function getBtnLabel(plan) {
  if (plan.title == "Freemium") {
    return "Freemium Plan";
  }

  if (isActivePlan(plan.paypalPlanId)) {
    return "Current Plan";
  }

  if (plan.title === "Enterprise") {
    return "Contact Sales";
  }

  if (plan.btnLabel) {
    return plan.btnLabel;
  }

  return "Select Plan";
}

function setAnnual(value) {
  isAnnual.value = value;
}

const plansData = ref([
  {
    ...plans.Freemium,
    paypalPlanId: plans.Freemium.paypalPlanId,
  },
  {
    ...plans.Basic,
    paypalPlanId: {
      monthly: "Monthly Standard",
      annual: "Annual Standard",
    },
  },
  {
    ...plans.Pro,
    paypalPlanId: {
      monthly: "Monthly Pro",
      annual: "Annual Pro",
    },
  },
  {
    ...plans.Enterprise,
    paypalPlanId: plans.Enterprise.paypalPlanId,
  },
]);

function updatePlansData() {
  if (!apiPlans.value) return;

  // Create a new array to store updated plans
  const updatedPlans = [];

  // Map API plan data to our format
  for (const [key, plan] of Object.entries(apiPlans.value)) {
    if (key === "Freemium") {
      updatedPlans.push({
        title: plan.title,
        description: plan.description,
        priceMonthly: plan.price_monthly,
        priceAnnual: plan.price_annual,
        creditsByDefault: plan.credits_by_default,
        features: plan.features,
        paypalPlanId: {
          monthly: "Freemium",
          annual: "Freemium",
        },
      });
    } else if (key === "Basic") {
      updatedPlans.push({
        title: plan.title,
        description: plan.description,
        priceMonthly: plan.price_monthly,
        priceAnnual: plan.price_annual,
        creditsByDefault: plan.credits_by_default,
        features: plan.features,
        paypalPlanId: {
          monthly: "Monthly Standard",
          annual: "Annual Standard",
        },
      });
    } else if (key === "Pro") {
      updatedPlans.push({
        title: plan.title,
        description: plan.description,
        priceMonthly: plan.price_monthly,
        priceAnnual: plan.price_annual,
        creditsByDefault: plan.credits_by_default,
        features: plan.features,
        paypalPlanId: {
          monthly: "Monthly Pro",
          annual: "Annual Pro",
        },
      });
    } else if (key === "Enterprise") {
      updatedPlans.push({
        title: plan.title,
        description: plan.description,
        priceMonthly: plan.price_monthly,
        priceAnnual: plan.price_annual,
        creditsByDefault: plan.credits_by_default,
        features: plan.features,
        paypalPlanId: {
          monthly: "Enterprise",
          annual: "Enterprise",
        },
      });
    }
  }

  // If we got plans from API, use them; otherwise keep the existing plans as fallback
  if (updatedPlans.length > 0) {
    plansData.value = updatedPlans;
  }
}

function redirectToCheckout(plan) {
  if (isActivePlan(plan.title)) {
    return;
  }

  if (plan.title.toLowerCase() === "enterprise") {
    sendEnterpriseInquiry();
  } else {
    store.commit("setSelectedPlan", plan);
    store.commit("setIsAnnual", isAnnual.value);
    router.push("/checkout");
  }
}

function sendEnterpriseInquiry() {
  const emailAddress = "sales@avacapo.com";
  const subject = encodeURIComponent("Enterprise Plan Inquiry");
  const body = encodeURIComponent(
    `Hello,\n\nI am interested in the Enterprise plan.\n\nPlease provide more details.\n\nThank you!`
  );

  const mailtoLink = `mailto:${emailAddress}?subject=${subject}&body=${body}`;
  window.location.href = mailtoLink;
}
</script>

<style scoped>
.plan-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.header-1 {
  margin-bottom: 5%;
}

.plan-content {
  display: flex;
  width: 90%;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.plan-card {
  width: 20%;
}

.toggle-container {
  display: inline-flex;
  width: 60%;
  justify-content: space-between;
  padding: 2px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 10%;
}

.toggle-option {
  width: 4.6rem;
  text-align: center;
  padding: 5px 10px;
  font-size: 0.7rem;
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  transition-duration: 0.5s;
}

.toggle-option.active {
  color: black;
  background-color: rgba(255, 255, 255, 0.7);
}

.plan-content-description {
  text-align: center;
  margin-bottom: 2rem;
}

@media (max-width: 970px) {
  .plan-content {
    flex-direction: column;
    align-items: center;
  }

  .plan-card {
    width: 90%;
    margin-bottom: 1rem;
  }
}
</style>
