<template>
    <div class="name-editor">
        <div v-if="!isEditing" class="display-name" @click="startEditing">
            <span class="name-text">{{ displayName }}</span>
            <BaseIcon iconName="pencil" class="edit-icon" color="rgba(255, 255, 255, 0.4)" :width="16" :height="16" />
        </div>
        <div v-else class="edit-mode">
            <input
                ref="inputRef"
                type="text"
                v-model="tempName"
                @blur="handleBlur"
                @keyup.enter="saveName"
                @keyup.esc="cancelEdit"
                :placeholder="modelValue || 'Enter your name'"
            />
            <div class="edit-actions">
                <button class="action-btn save" @click="saveName">
                    <BaseIcon iconName="check" :width="20" :height="20" />
                </button>
                <button class="action-btn cancel" @click="cancelEdit">
                    <BaseIcon iconName="close" :width="20" :height="20" />
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, nextTick, computed } from 'vue';
import BaseIcon from './BaseIcon.vue';

const props = defineProps({
    modelValue: {
        type: String,
        required: true
    }
});

const emit = defineEmits(['update:modelValue', 'save']);

const isEditing = ref(false);
const tempName = ref('');
const inputRef = ref(null);

const displayName = computed(() => {
    const maxLength = 30;
    if (!props.modelValue) return 'Not set';
    return props.modelValue.length > maxLength 
        ? props.modelValue.slice(0, maxLength) + '...' 
        : props.modelValue;
});

function startEditing() {
    tempName.value = props.modelValue;
    isEditing.value = true;
    nextTick(() => {
        inputRef.value?.focus();
    });
}

function cancelEdit() {
    isEditing.value = false;
    tempName.value = props.modelValue;
}

function handleBlur(event) {
    // Don't cancel if clicking the save button
    if (!event.relatedTarget?.classList.contains('save')) {
        cancelEdit();
    }
}

async function saveName() {
    const trimmedName = tempName.value.trim();
    if (trimmedName && trimmedName !== props.modelValue) {
        emit('save', trimmedName);
    }
    isEditing.value = false;
}
</script>

<style scoped>
.name-editor {
    width: 100%;
    min-height: 32px; /* Add fixed height to prevent jumping */
}

.display-name {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 8px;
    min-height: 32px; /* Match parent height */
}

.name-text {
    color: rgba(255, 255, 255, 0.9);
    font-weight: 500;
    line-height: 32px; /* Match height for vertical centering */
}

.edit-icon {
    transition: color 0.2s;
    display: flex;
    align-items: center;
}

.display-name:hover .edit-icon {
    color: rgba(255, 255, 255, 0.7);
}

.edit-mode {
    display: flex;
    gap: 8px;
    align-items: center;
    min-height: 32px; /* Match parent height */
}

input {
    flex: 1;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    padding: 4px 12px;
    color: white;
    font-size: inherit;
    height: 32px; /* Fixed height to match display mode */
    line-height: 32px;
}

input:focus {
    outline: none;
    border-color: #20AD8B;
}

.edit-actions {
    display: flex;
    gap: 4px;
    height: 32px; /* Match parent height */
}

.action-btn {
    background: none;
    border: none;
    padding: 4px;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px; /* Match parent height */
    width: 32px; /* Make it square */
}

.action-btn.save {
    color: #20AD8B;
}

.action-btn.save:hover {
    background: rgba(32, 173, 139, 0.1);
}

.action-btn.cancel {
    color: #FF4444;
}

.action-btn.cancel:hover {
    background: rgba(255, 68, 68, 0.1);
}
</style> 