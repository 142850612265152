<template>
    <div class="cancel-container">
        <h1 class="header-1">We are sorry to see you go!</h1>
        <p class="description">
            Please let us know why you're canceling your subscription.
        </p>

        <div class="reasons">
            <label v-for="(reason, index) in reasons" :key="index" class="radio-option">
                <input type="radio" :value="reason" v-model="selectedReason" />
                <span class="custom-radio"></span>
                {{ reason }}
            </label>

            <textarea v-if="selectedReason === 'Other'" v-model="otherReason"
                placeholder="Please specify your reason..." class="other-input">
            </textarea>
        </div>

        <div class="actions">
            <PrimaryBtn class="switch-plan-btn" @click="handleSwitchPlan">
                Switch to another plan
            </PrimaryBtn>
            <button class="cancel-anyway-btn" :disabled="!isFormValid" @click="handleCancel">
                Cancel anyway
            </button>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import PrimaryBtn from "@/components/PrimaryBtn.vue";
import { subscriptionAPI } from "@/services/api";

const router = useRouter();
const store = useStore();

const reasons = [
    "No longer needed",
    "Too expensive",
    "Not satisfied with the service",
    "Switching to another provider",
    "Other"
];

const selectedReason = ref("");
const otherReason = ref("");

const isFormValid = computed(() => {
    return selectedReason.value && (selectedReason.value !== "Other" || otherReason.value.trim() !== "");
});

const handleSwitchPlan = () => {
    router.push("/plans");
};

const handleCancel = async () => {
    if (!isFormValid.value) return;

    try {
        const finalReason = selectedReason.value === "Other" ? otherReason.value : selectedReason.value;
        const sessionId = store.state.sessionId;
        const subscriptionId = store.state.userDetails?.subscription_id;

        if (!subscriptionId) {
            throw new Error('No subscription ID found');
        }

        await subscriptionAPI.cancelSubscription(
            subscriptionId,
            encodeURIComponent(finalReason),
            sessionId
        );

        // Refresh user details after cancellation
        await store.dispatch('fetchUserDetails');
        
        router.push("/subcancelsuccess");
    } catch (error) {
        console.error("Error canceling subscription:", error);
        // You might want to show an error message to the user here
    }
};
</script>

<style scoped>
.header-1 {
    margin-top: 0;
}

.cancel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    width: 80%;
    max-width: 600px;
    padding: 40px;
    border-radius: 16px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.description {
    color: #999;
    margin: 0 0 20px;
}

.reasons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 85%;
    gap: 12px;
}

.radio-option {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    color: white;
    position: relative;
}

.radio-option input {
    display: none;
}

.custom-radio {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.2);
    margin-right: 12px;
    transition: all 0.3s ease;
    position: relative;
}

.radio-option input:checked+.custom-radio {
    background: linear-gradient(99.6deg, #20AD8B 11.39%, #ACE41B 86.3%);
}

.other-input {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: none;
    background: rgba(255, 255, 255, 0.2);
    color: white;
    font-size: 14px;
    outline: none;
    resize: none;
    min-height: 60px;
}

.actions {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.switch-plan-btn {
    width: 100%;
    max-width: 300px;
    margin-bottom: 10px;
}

.cancel-anyway-btn {
    width: 100%;
    max-width: 300px;
    padding: 10px;
    border: none;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.2);
    color: white;
    cursor: pointer;
    transition: background 0.3s ease;
    text-align: center;
}

.cancel-anyway-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.cancel-anyway-btn:hover:not(:disabled) {
    background: rgba(255, 255, 255, 0.4);
}
</style>
