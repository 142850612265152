<template>
  <p>{{ name }}</p>

  <div class="project-list-wrapper" :class="{ 'no-projects': projectItems.length === 0 }">
    <div v-if="isError" class="error-container">
      <BaseIcon iconName="alert" color="#ff6b6b" />
      <span>Failed to load projects</span>
      <SecondBtn @click="$emit('retry')">Try again</SecondBtn>
    </div>
    <div v-else-if="isLoading">
      <div class="loading-dots">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div v-else-if="projectItems.length > 0"
      :class="{ 'project-list': true, 'single-row': isSingleRow, 'multi-row': !isSingleRow }">
      <ProjectItem v-for="(item, index) in sortedProjectItems" :key="index" :imgUrl="item.imgUrl"
        :imgName="item.user_animation_name" :imgDate="item.date" :id="String(item.hashed_id)"
        :isFavorite="item.isFavorite" :isShowFavoriteStar="isFavoriteInline" :viewed="item.viewed"
        :animationStatus="item.animation_status" />
    </div>

    <div v-else class="placeholder">
      <BaseIcon :iconName="iconName" color="rgba(255,255,255, .4)"></BaseIcon>
      <p class="caption">{{ captionText }}</p>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import ProjectItem from '@/components/ProjectItem.vue';
import BaseIcon from '@/components/BaseIcon.vue';
import SecondBtn from '@/components/SecondBtn.vue';

const props = defineProps({
  name: String,
  projectItems: {
    type: Array,
    default: () => [],
  },
  isSingleRow: {
    type: Boolean,
    default: true,
  },
  locked: {
    type: Boolean,
    default: false,
  },
  isFavoriteInline: {
    type: Boolean,
    default: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  error: {
    type: Boolean,
    default: false
  }
});

const isLoading = ref(!props.locked);
const isError = ref(false);

defineEmits(['retry']);

watch(() => props.loading, (newValue) => {
  isLoading.value = newValue;
});

watch(() => props.error, (newValue) => {
  isError.value = newValue;
});

const sortedProjectItems = computed(() => {
  return props.projectItems.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
});

const iconName = computed(() => (props.locked ? 'lock' : 'dotHorizontal'));

const captionText = computed(() => {
  if (props.locked) {
    return 'It will be available in the next update';
  } else {
    return `You don't have any ${props.name.toLowerCase()} yet`;
  }
});
</script>

<style scoped>
.project-list {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 10px;
}

.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #666;
  font-size: 18px;
}

.no-projects {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, .1);
  color: rgba(255, 255, 255, .4);
}

.caption {
  color: rgba(255, 255, 255, .4);
}

.single-row {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 20px;
  padding: 4px 4px 12px 4px;
  align-items: flex-start;
}

.multi-row {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  width: 100%;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  padding-right: 4px;
}

@media (max-width: 768px) {
  .multi-row {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }

  .single-row {
    gap: 15px;
    padding: 4px 4px 12px 4px;
  }
}

.loading-container {
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 20px;
}

.loading-dots {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.loading-dots span {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.4);
  animation: dots 1.4s infinite;
  opacity: 0.3;
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dots {

  0%,
  100% {
    opacity: 0.3;
    transform: scale(0.8);
  }

  50% {
    opacity: 0.7;
    transform: scale(1.2);
  }
}

.loading-container span {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
}

.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
}

.error-container span {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
}

.error-container :deep(.second-btn) {
  margin-top: 8px;
  min-width: 120px;
}
</style>