<template>
    <div class="toggle" :class="{ checked: localValue, locked }" @click="onToggle">
        <div class="toggle-circle"></div>
        <div v-if="locked" class="lock-icon">
            <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M2.30002 4.39995V3.94281C2.30002 2.42335 3.50431 1.19995 5.00002 1.19995C6.49574 1.19995 7.70003 2.42335 7.70003 3.94281V4.39995M2.30002 4.39995C1.80502 4.39995 1.40002 4.81138 1.40002 5.31424V9.88566C1.40002 10.3885 1.80502 10.7999 2.30002 10.7999H7.70003C8.19503 10.7999 8.60003 10.3885 8.60003 9.88566V5.31424C8.60003 4.81138 8.19503 4.39995 7.70003 4.39995M2.30002 4.39995H7.70003"
                    stroke="white" stroke-opacity="0.4" stroke-linecap="round" />
            </svg>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from "vue"

const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false
    },
    locked: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(["update:modelValue"])

const localValue = ref(props.modelValue)

watch(
    () => props.modelValue,
    newVal => {
        localValue.value = newVal
    }
)

function onToggle() {
    if (props.locked) return
    localValue.value = !localValue.value
    emit("update:modelValue", localValue.value)
}
</script>

<style scoped>
.toggle {
    width: 3rem;
    height: 1.5rem;
    background-color: #ccc;
    border-radius: 0.75rem;
    position: relative;
    cursor: pointer;
    transition: background-color 0.2s ease;
    display: inline-block;
}

.toggle.checked {
    background-color: #3EAF3F;
}

.toggle.locked {
    background-color: #999 !important;
    cursor: not-allowed;
}

.toggle-circle {
    width: 1.2rem;
    height: 1.2rem;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 0.15rem;
    left: 0.15rem;
    transition: transform 0.2s ease;
}

.toggle.checked .toggle-circle {
    transform: translateX(1.5rem);
}

.lock-icon {
    position: absolute;
    right: .5rem;
    top: 0.15rem;
    pointer-events: none;
}
</style>