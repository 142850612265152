<script setup>
import { computed } from "vue";

import EnvelopIcon from "./icons/EnvelopIcon.vue";
import StarIcon from "./icons/StarIcon.vue";
import HamburgerIcon from "./icons/HamburgerIcon.vue";
import LockIcon from "./icons/LockIcon.vue";
import HomeIcon from "./icons/HomeIcon.vue";
import FolderIcon from "./icons/FolderIcon.vue";
import BookIcon from "./icons/BookIcon.vue";
import DownloadIcon from "./icons/DownloadIcon.vue";
import BellIcon from "./icons/BellIcon.vue";
import BinIcon from "./icons/BinIcon.vue";
import DotHorizontalIcon from "./icons/DotHorizontalIcon.vue";
import ChevronLeftIcon from "./icons/ChevronLeftIcon.vue";
import ChevronRightIcon from "./icons/ChevronRightIcon.vue";
import SkullIcon from "./icons/SkullIcon.vue";
import RotateIcon from "./icons/RotateIcon.vue";
import PlusIcon from "./icons/PlusIcon.vue";
import MinusIcon from "./icons/MinusIcon.vue";
import PanIcon from "./icons/PanIcon.vue";
import CameraIcon from "./icons/CameraIcon.vue";
import SettingsIcon from "./icons/SettingsIcon.vue";
import LayersIcon from "./icons/LayersIcon.vue";
import PauseIcon from "./icons/PauseIcon.vue";
import PlayIcon from "./icons/PlayIcon.vue";
import FastForwardIcon from "./icons/FastForwardIcon.vue";
import RotateLeftIcon from "./icons/RotateLeftIcon.vue";
import PencilIcon from "./icons/PencilIcon.vue";
import ObjectIcon from "./icons/ObjectIcon.vue";
import LogoutIcon from "./icons/LogoutIcon.vue";
import BarGroupIcon from "./icons/BarGroupIcon.vue";
import HelpIcon from "./icons/HelpIcon.vue";
import CreditIcon from "./icons/CreditIcon.vue";
import EyeOpenedIcon from "./icons/EyeOpenedIcon.vue";
import EyeClosedIcon from "./icons/EyeClosedIcon.vue";
import CalendarIcon from "./icons/CalendarIcon.vue";
import PersonIcon from "./icons/PersonIcon.vue";
import CheckIcon from "./icons/CheckIcon.vue";
import CloseIcon from "./icons/CloseIcon.vue";
import CopyIcon from "./icons/CopyIcon.vue";
import AlertIcon from "./icons/AlertIcon.vue";
import ErrorIcon from "./icons/ErrorIcon.vue";

const props = defineProps({
  iconName: {
    type: String,
    required: true,
  },
  width: {
    type: Number,
    default: 24,
  },
  height: {
    type: Number,
    default: 24,
  },
  color: {
    type: String,
    default: "white",
  },
  notification: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: "",
  },
});

const icons = {
  star: StarIcon,
  envelop: EnvelopIcon,
  hamburger: HamburgerIcon,
  lock: LockIcon,
  home: HomeIcon,
  folder: FolderIcon,
  book: BookIcon,
  download: DownloadIcon,
  bell: BellIcon,
  bin: BinIcon,
  dotHorizontal: DotHorizontalIcon,
  chevronLeft: ChevronLeftIcon,
  chevronRight: ChevronRightIcon,
  skull: SkullIcon,
  rotate: RotateIcon,
  plus: PlusIcon,
  minus: MinusIcon,
  pan: PanIcon,
  camera: CameraIcon,
  settings: SettingsIcon,
  layers: LayersIcon,
  pause: PauseIcon,
  play: PlayIcon,
  fastForward: FastForwardIcon,
  rotateLeft: RotateLeftIcon,
  pencil: PencilIcon,
  object: ObjectIcon,
  logout: LogoutIcon,
  barGroup: BarGroupIcon,
  help: HelpIcon,
  credit: CreditIcon,
  eyeOpened: EyeOpenedIcon,
  eyeClosed: EyeClosedIcon,
  calendar: CalendarIcon,
  person: PersonIcon,
  check: CheckIcon,
  close: CloseIcon,
  copy: CopyIcon,
  alert: AlertIcon,
  error: ErrorIcon,
};

const icon = computed(() => {
  return icons[props.iconName] || null;
});
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    :aria-labelledby="iconName"
    role="presentation"
    fill="none"
  >
    <title :id="iconName" lang="en">{{ label }} icon</title>
    <component :is="icon" :color="color" :notification="notification"></component>
  </svg>
</template>
