<script setup>
const { color } = defineProps({
  color: {
    type: String,
  },
})
</script>

<template>
  <g>
    <path
      d="M1 12C2.5 7 7 3 12 3C17 3 21.5 7 23 12C21.5 17 17 21 12 21C7 21 2.5 17 1 12Z"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle cx="12" cy="12" r="3" :stroke="color" stroke-width="2" />
  </g>
</template>