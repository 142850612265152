<template>
    <div v-if="show" class="support-overlay" @click="closePopup">
        <div class="support-modal" @click.stop>
            <h3 class="header-2">Support & Feedback</h3>

            <div class="support-options">
                <div class="email-container">
                    <a href="mailto:support@avacapo.com" class="email-link gradient-text">
                        support@avacapo.com
                    </a>
                    <button class="copy-btn" @click="copyEmail">
                        <BaseIcon :iconName="copied ? 'check' : 'copy'" :color="copied ? '#20AD8B' : 'white'"
                            :width="16" :height="16" />
                    </button>
                </div>
                <p class="email-note">We typically respond within 24 hours during business days.</p>
                <div class="form-actions">
                    <button class="cancel-btn" @click="closePopup">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import BaseIcon from './BaseIcon.vue';

defineProps({
    show: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['close']);
const copied = ref(false);

function closePopup() {
    emit('close');
}

function copyEmail() {
    navigator.clipboard.writeText('support@avacapo.com');
    copied.value = true;
    setTimeout(() => {
        copied.value = false;
    }, 2000);
}
</script>

<style scoped>
.support-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1001;
    backdrop-filter: blur(4px);
}

.support-modal {
    background: rgba(42, 41, 42, 1);
    padding: 2rem;
    border-radius: 16px;
    width: 90%;
    max-width: 500px;
    box-sizing: border-box;
}

.support-modal h3 {
    margin: 0 0 1.5rem 0;
    color: rgba(255, 255, 255, 0.9);
}

.email-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin: 1rem 0;
}

.email-link {
    text-decoration: none;
    font-size: 1.2rem;
    line-height: 1.2rem;
    transition: opacity 0.3s;
}

.email-link:hover {
    opacity: 0.8;
}

.copy-btn {
    background: none;
    border: none;
    padding: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: background 0.3s;
}

.copy-btn:hover {
    background: rgba(255, 255, 255, 0.1);
}

.email-note {
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.9rem;
    margin-bottom: 1rem;
}

.form-actions {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.cancel-btn {
    padding: 8px 24px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    background: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.7);
    transition: all 0.3s ease;
}

.cancel-btn:hover {
    background: rgba(255, 255, 255, 0.2);
}

@media (max-width: 480px) {
    .support-modal {
        padding: 1.5rem;
        width: 95%;
    }

    .cancel-btn {
        width: 100%;
        padding: 12px;
    }
}
</style>