<template>
    <div v-if="!hasConsent" class="cookie-consent">
        <div class="cookie-content">
            <div class="cookie-text">
                <p>We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies.</p>
            </div>
            <div class="cookie-actions">
                <button class="accept-btn" @click="acceptCookies">Accept</button>
                <button class="settings-btn" @click="openSettings">Cookie Settings</button>
            </div>
        </div>
    </div>

    <div v-if="showSettings" class="cookie-settings-overlay" @click="showSettings = false">
        <div class="cookie-settings-modal" @click.stop>
            <h3>Cookie Settings</h3>
            <div class="settings-content">
                <div class="cookie-option">
                    <div class="option-header">
                        <h4>Essential Cookies</h4>
                        <ToggleComponent
                            v-model="essentialEnabled"
                            :locked="true"
                        />
                    </div>
                    <p>Required for the website to function properly. Cannot be disabled.</p>
                </div>

                <div class="cookie-option">
                    <div class="option-header">
                        <h4>Analytics Cookies</h4>
                        <ToggleComponent
                            v-model="analyticsEnabled"
                            :locked="false"
                        />
                    </div>
                    <p>Help us understand how visitors interact with our website.</p>
                </div>
            </div>
            <div class="settings-actions">
                <button class="save-btn" @click="saveSettings">Save Preferences</button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import Cookies from 'js-cookie';
import ToggleComponent from './ToggleComponent.vue';

const hasConsent = ref(false);
const showSettings = ref(false);
const analyticsEnabled = ref(true);
const essentialEnabled = ref(true);

onMounted(() => {
    const consent = Cookies.get('cookie-consent');
    if (consent) {
        hasConsent.value = true;
        analyticsEnabled.value = Cookies.get('analytics-enabled') === 'true';
    }
});

function acceptCookies() {
    Cookies.set('cookie-consent', 'true', { expires: 365 });
    Cookies.set('analytics-enabled', 'true', { expires: 365 });
    hasConsent.value = true;
}

function openSettings() {
    showSettings.value = true;
}

function saveSettings() {
    Cookies.set('cookie-consent', 'true', { expires: 365 });
    Cookies.set('analytics-enabled', analyticsEnabled.value, { expires: 365 });
    hasConsent.value = true;
    showSettings.value = false;
}
</script>

<style scoped>
.cookie-consent {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(42, 41, 42, 0.95);
    backdrop-filter: blur(10px);
    z-index: 1000;
    padding: 1rem;
    box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
}

.cookie-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
}

.cookie-text {
    color: rgba(255, 255, 255, 0.9);
    font-size: 0.9rem;
    line-height: 1.5;
}

.cookie-actions {
    display: flex;
    gap: 1rem;
    flex-shrink: 0;
}

.accept-btn, .settings-btn {
    padding: 8px 16px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.3s ease;
}

.accept-btn {
    background: linear-gradient(135deg, #20AD8B 0%, #ACE41B 100%);
    color: white;
}

.accept-btn:hover {
    opacity: 0.9;
}

.settings-btn {
    background: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.7);
}

.settings-btn:hover {
    background: rgba(255, 255, 255, 0.2);
}

.cookie-settings-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1001;
    backdrop-filter: blur(4px);
}

.cookie-settings-modal {
    background: rgba(42, 41, 42, 1);
    padding: 2rem;
    border-radius: 16px;
    width: 90%;
    max-width: 500px;
}

.cookie-settings-modal h3 {
    margin: 0 0 1.5rem 0;
    color: rgba(255, 255, 255, 0.9);
}

.settings-content {
    margin-bottom: 2rem;
}

.cookie-option {
    margin-bottom: 1.5rem;
}

.option-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.option-header h4 {
    margin: 0;
    color: rgba(255, 255, 255, 0.8);
}

.cookie-option p {
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.9rem;
    margin: 0;
}

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggle-switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.1);
    transition: 0.3s;
    border-radius: 24px;
}

.toggle-switch .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.3s;
    border-radius: 50%;
}

.toggle-switch input:checked + .slider {
    background: linear-gradient(135deg, #20AD8B 0%, #ACE41B 100%);
}

.toggle-switch input:checked + .slider:before {
    transform: translateX(26px);
}

.toggle-switch.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.toggle-switch.disabled .slider {
    cursor: not-allowed;
}

.settings-actions {
    display: flex;
    justify-content: flex-end;
}

.save-btn {
    padding: 8px 24px;
    border-radius: 8px;
    border: none;
    background: linear-gradient(135deg, #20AD8B 0%, #ACE41B 100%);
    color: white;
    cursor: pointer;
    font-size: 14px;
    transition: opacity 0.3s;
}

.save-btn:hover {
    opacity: 0.9;
}

@media (max-width: 768px) {
    .cookie-content {
        flex-direction: column;
        text-align: center;
    }

    .cookie-actions {
        width: 100%;
        justify-content: center;
    }
}
</style> 