<template>
    <div class="section">
        <div class="center-section">
            <div class="form-heading">
                <h1 class="header-1">Forgot your password?</h1>
                <p class="caption">
                    Enter your email address below and we'll get you back on track
                </p>
            </div>
           
            <div class="signup-form">
                <form @submit.prevent="handleSubmit">
                    <base-input class="form-group" 
                        type="email" 
                        placeholder="Email" 
                        required 
                        icon="envelop"
                        v-model="email"
                        :error="error">
                    </base-input>

                    <primary-btn :disabled="!isValid || isLoading">
                        {{ isLoading ? 'Sending...' : 'Send Reset Link' }}
                    </primary-btn>
                </form>
            </div>

            <div class="login-link caption">
                <router-link to="/login" class="link">
                    Back to sign in
                </router-link>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import BaseInput from '@/components/BaseInput.vue';
import PrimaryBtn from '@/components/PrimaryBtn.vue';
import { authAPI } from '@/services/api';

const email = ref('');
const error = ref('');
const isLoading = ref(false);

const router = useRouter();

const isValid = computed(() => {
    return email.value && email.value.includes('@');
});

async function handleSubmit() {
    if (!isValid.value) {
        error.value = 'Please enter a valid email address';
        return;
    }

    isLoading.value = true;
    error.value = '';

    try {
        await authAPI.resetPasswordEmail(email.value);
        router.push('/resetpass/confirm');
    } catch (err) {
        error.value = err.message || 'Failed to send reset email';
    } finally {
        isLoading.value = false;
    }
}
</script>

<style scoped>
.section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.center-section {
    width: 35%;
    min-width: 300px;
}

.form-heading {
    text-align: center;
    margin-bottom: 24px;
}

.form-heading h1 {
    margin-bottom: 12px;
}

.form-heading .caption {
    color: rgba(255, 255, 255, .4);
    margin: 0 auto;
    max-width: 400px;
}

.signup-form {
    padding: 24px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    margin-bottom: 24px;
}

.form-group {
    margin-bottom: 16px;
}

.login-link {
    text-align: center;
    color: rgba(255, 255, 255, .4);
}

.link {
    color: white;
    text-decoration: none;
    transition: opacity 0.2s;
}

.link:hover {
    opacity: 0.8;
}

@media (max-width: 768px) {
    .center-section {
        width: 90%;
    }
}
</style>