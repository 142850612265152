<template>
  <ProjectList
    name="Animations"
    :projectItems="projectItems"
    :isSingleRow="false"
    :loading="isInitialLoad ? isLoading : false"
    :error="isError"
    @retry="fetchProjectItems"
  />
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch } from "vue";

import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

import { fetchWithAuthCheck } from "@/services/api";

const store = useStore();
const router = useRouter();
const route = useRoute();

import ProjectList from "@/components/ProjectList.vue";

const projectItems = ref([]);
const isLoading = ref(true);
const isError = ref(false);
const pollingInterval = ref(null);
const isInitialLoad = ref(true);
const isActive = ref(true);

async function fetchProjectItems() {
  if (isInitialLoad.value) {
    isLoading.value = true;
  }
  isError.value = false;

  try {
    const sessionId = store.getters.getSessionId;
    const response = await fetchWithAuthCheck(
      `${process.env.VUE_APP_BACKEND_URL}/get-animation-list/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "session-id": sessionId,
        },
      },
      router,
      store
    );
    const data = await response.json();
    projectItems.value = data;

    if (
      data.some(
        (item) => item.animation_status === "Started" || item.animation_status === "None"
      )
    ) {
      startPolling();
    } else {
      stopPolling();
    }
  } catch (error) {
    console.error("Error fetching project items:", error);
    projectItems.value = [];
    isError.value = true;
  } finally {
    if (isInitialLoad.value) {
      isLoading.value = false;
      isInitialLoad.value = false;
    }
  }
}

function startPolling() {
  stopPolling(); // Clear any existing interval first

  if (!isActive.value) return; // Don't start polling if not active

  pollingInterval.value = setInterval(() => {
    if (isActive.value) {
      fetchProjectItems();
    } else {
      stopPolling();
    }
  }, 5000);
}

function stopPolling() {
  if (pollingInterval.value) {
    clearInterval(pollingInterval.value);
    pollingInterval.value = null;
  }
}

// Watch for route changes to stop polling when navigating away
watch(
  () => route.path,
  (newPath) => {
    isActive.value = newPath === "/app/animations";
    if (!isActive.value) {
      stopPolling();
    } else {
      // If we're navigating to the animations page, check if we need to start polling
      if (
        projectItems.value.some(
          (item) =>
            item.animation_status === "Started" || item.animation_status === "None"
        )
      ) {
        startPolling();
      }
    }
  }
);

onMounted(() => {
  isActive.value = route.path === "/app/animations";
  fetchProjectItems();
});

onUnmounted(() => {
  isActive.value = false;
  stopPolling();
});
</script>

<style scoped>
.actions-group {
  display: flex;
  align-items: center;
  width: 100%;
  height: 20%;
}

.content-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 33%;
}

.content-section-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.4);
}

.content-section-list p {
  color: rgba(255, 255, 255, 0.4);
}

.description p.header-2 {
  margin-bottom: 0;
}

.action-item {
  margin-right: 15px;
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 15px;
  padding: 15px 20px;
  cursor: pointer;
}

.action-item:hover {
  background-color: rgba(255, 255, 255, 0.3);
  transition-duration: 0.5s;
}

.description .caption {
  color: rgba(255, 255, 255, 0.4);
  width: 90%;
}

.action-item img {
  height: 100%;
  width: auto;
}

.action-btn {
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  color: black;
}
</style>
