<template>
  <div class="animation-wrapper">
    <div class="anim-controls">
      <HoverPopover message="Show/hide the character's skeleton">
        <div class="control-btn" @click="toggleSkeletonVisibility">
          <BaseIcon iconName="skull"></BaseIcon>
        </div>
      </HoverPopover>

      <HoverPopover message="Rotate/Pan view">
        <div class="control-btn" @click="togglePanMode">
          <BaseIcon :iconName="isPanModeEnabled ? 'pan' : 'rotate'"></BaseIcon>
        </div>
      </HoverPopover>

      <HoverPopover message="Reset camera position">
        <div class="control-btn" @click="resetCamera">
          <BaseIcon iconName="camera"></BaseIcon>
        </div>
      </HoverPopover>
    </div>

    <div ref="threejsContainer" class="threejs-container"></div>

    <div class="scale-controls">
      <HoverPopover message="Zoom in">
        <div class="control-btn" @click="zoomIn">
          <BaseIcon iconName="plus"></BaseIcon>
        </div>
      </HoverPopover>
      <HoverPopover message="Zoom out">
        <div class="control-btn" @click="zoomOut">
          <BaseIcon iconName="minus"></BaseIcon>
        </div>
      </HoverPopover>
    </div>
  </div>

  <div class="animation-footer">
    <div class="control-btn locked" @click="handleHomeButton">
      <BaseIcon iconName="layers" color="rgba(255,255,255,0.4)"> </BaseIcon>
    </div>

    <div class="playbar">
      <div
        class="progress-container"
        @click="setAnimationProgress($event)"
        @mousedown="startDragging"
        @mouseup="stopDragging"
        @mouseleave="stopDragging"
        @mousemove="dragUpdate"
      >
        <div class="progress-bar" :style="{ width: progressBarWidth + '%' }"></div>
      </div>

      <div class="time-codes">
        <div>{{ formattedCurrentTime }}</div>
        <div>{{ formattedDuration }}</div>
      </div>
      <div class="playbar-controls">
        <div class="control-btn rotate" @click="previousFrame">
          <BaseIcon iconName="fastForward"></BaseIcon>
        </div>
        <div class="control-btn" @click="toggleAnimation">
          <BaseIcon :iconName="iconName"></BaseIcon>
        </div>
        <div class="control-btn" @click="nextFrame">
          <BaseIcon iconName="fastForward"></BaseIcon>
        </div>
        <div class="control-btn" @click="resetToFirstFrame">
          <BaseIcon iconName="rotateLeft"></BaseIcon>
        </div>
      </div>
    </div>

    <div class="control-btn" @click="openSupportPopup">
      <BaseIcon iconName="help"></BaseIcon>
    </div>

    <SupportPopup :show="showSupportPopup" @close="showSupportPopup = false" />
  </div>
</template>

<script setup>
import BaseIcon from "@/components/BaseIcon.vue";
import HoverPopover from "@/components/HoverPopover.vue";
import SupportPopup from "@/components/SupportPopup.vue";
import { ref, reactive, computed, watch, onMounted, onBeforeUnmount } from "vue";

import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

function setPlaybackSpeed(speed) {
  animationActions.forEach((action) => {
    action.timeScale = speed;
  });
}

const props = defineProps({
  animationId: String,
  modelBuffer: {
    type: ArrayBuffer,
    required: false,
    default: null
  }
});

const threejsContainer = ref(null);
const clock = new THREE.Clock();
let scene, camera, renderer, controls, loadedScene, skeletonMesh;
const mixers = [];
let animationActions = [];
let isInitialized = false;

const iconName = ref("pause");
const isPanModeEnabled = ref(false);
const zoomStep = 1;

const progressBarWidth = ref(0);
const animationDetails = reactive({
  duration: 0,
  currentTime: 0,
});
const isAnimationPlaying = ref(true);

const formattedCurrentTime = computed(() => formatTime(animationDetails.currentTime));
const formattedDuration = computed(() => formatTime(animationDetails.duration));

const currentFrame = ref(0);
const totalFrames = computed(() => {
  if (animationActions.length > 0 && animationActions[0].getClip()) {
    return animationActions[0].getClip().duration * 30;
  }
  return 0;
});

const dragging = ref(false);

function nextFrame() {
  if (currentFrame.value < totalFrames.value - 1) {
    currentFrame.value++;
    updateAnimationFrame();
  }
}

function previousFrame() {
  if (currentFrame.value > 0) {
    currentFrame.value--;
    updateAnimationFrame();
  }
}

function resetToFirstFrame() {
  currentFrame.value = 0;
  updateAnimationFrame();
}

function updateAnimationFrame() {
  const timePerFrame = 1 / 30;
  const newTime = currentFrame.value * timePerFrame;
  animationActions.forEach((action) => {
    action.paused = true;
    action.time = newTime;
    action.play();
    action.paused = true;
  });

  animationDetails.currentTime = newTime;
  progressBarWidth.value = (newTime / animationDetails.duration) * 100;
}

function handleKeyPress(event) {
  if (event.target.tagName === "INPUT") return;

  switch (event.code) {
    case "Space":
      event.preventDefault();
      toggleAnimation();
      break;
    case "ArrowRight":
      event.preventDefault();
      nextFrame();
      break;
    case "ArrowLeft":
      event.preventDefault();
      previousFrame();
      break;
    case "Home":
      event.preventDefault();
      resetToFirstFrame();
      break;
  }
}

const showSupportPopup = ref(false);

function openSupportPopup() {
  showSupportPopup.value = true;
}

onMounted(() => {
  if (threejsContainer.value) {
    initThreeJS();
    addGridFloor();
    animate();
    isInitialized = true;

    document.addEventListener("keydown", handleKeyPress);

    if (props.modelBuffer) {
      loadModel(props.modelBuffer);
    }
  }
});

onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeyPress);
  window.removeEventListener("resize", onWindowResize);
});

watch(
  () => props.modelBuffer,
  async (newBuffer) => {
    if (newBuffer && isInitialized) {
      loadModel(newBuffer);
    }
  }
);

function loadModel(buffer) {
  if (!buffer) return;
  
  try {
    const loader = new GLTFLoader();
    loader.parse(
      buffer,
      "",
      (gltf) => {
        handleGLTFLoaded(gltf);
      },
      (error) => {
        console.error("Error parsing GLTF model:", error);
      }
    );
  } catch (error) {
    console.error("Error loading model:", error);
  }
}

function initThreeJS() {
  const width = threejsContainer.value.clientWidth;
  const height = threejsContainer.value.clientHeight;

  scene = new THREE.Scene();
  scene.background = new THREE.Color("rgb(55, 55, 55)");

  camera = new THREE.PerspectiveCamera(45, width / height, 0.1, 1000);
  camera.position.set(0, 5, 10);

  renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.setSize(width, height);
  threejsContainer.value.appendChild(renderer.domElement);

  controls = new OrbitControls(camera, renderer.domElement);
  controls.enableDamping = true;
  controls.enablePan = true;

  setupLights();

  window.addEventListener("resize", onWindowResize);
}

function onWindowResize() {
  if (camera && renderer && threejsContainer.value) {
    const width = threejsContainer.value.clientWidth;
    const height = threejsContainer.value.clientHeight;

    camera.aspect = width / height;
    camera.updateProjectionMatrix();
    renderer.setSize(width, height);
  }
}

function setupLights() {
  const ambientLight = new THREE.AmbientLight(0xffffff, 1);
  const directionalLight1 = new THREE.DirectionalLight(0xffffff, 1.2);
  directionalLight1.position.set(1, 1, 0).normalize();

  const directionalLight2 = new THREE.DirectionalLight(0xffffff, 1.2);
  directionalLight2.position.set(-1, 1, 0).normalize();

  const directionalLight3 = new THREE.DirectionalLight(0xffffff, 1.2);
  directionalLight3.position.set(0, 1, 1).normalize();

  scene.add(ambientLight, directionalLight1, directionalLight2, directionalLight3);
}

function handleGLTFLoaded(gltf) {
  scene.remove(loadedScene);
  loadedScene = gltf.scene;
  scene.add(loadedScene);

  skeletonMesh = createBlenderStyleSkeleton(loadedScene);
  setupGLTFModel(gltf);
}

function setupGLTFModel(gltf) {
  if (gltf.animations && gltf.animations.length) {
    setupGLTFAnimations(gltf.animations);
  }
  adjustCameraToFitModel();
}

function setupGLTFAnimations(animations) {
  const mixer = new THREE.AnimationMixer(loadedScene);
  mixers.push(mixer);
  animations.forEach((clip) => {
    const action = mixer.clipAction(clip);
    action.play();
    animationActions.push(action);
  });

  animationDetails.duration = mixer.clipAction(animations[0]).getClip().duration;
}

function createBlenderStyleSkeleton(skeleton) {
  const boneMeshes = [];

  skeleton.traverse((bone) => {
    if (bone.isBone) {
      bone.children.forEach((child) => {
        if (child.isBone) {
          const start = bone.getWorldPosition(new THREE.Vector3());
          const end = child.getWorldPosition(new THREE.Vector3());

          const direction = new THREE.Vector3().subVectors(end, start);
          const length = direction.length();

          const baseRadius = length * 0.1;
          const height = length;

          const geometry = new THREE.CylinderGeometry(0, baseRadius, height, 4);

          const material = new THREE.MeshBasicMaterial({
            color: "orange",
          });

          const edgeMaterial = new THREE.LineBasicMaterial({
            color: "black",
          });

          const pyramid = new THREE.Mesh(geometry, material);

          const edges = new THREE.EdgesGeometry(geometry);
          const edgeLines = new THREE.LineSegments(edges, edgeMaterial);
          pyramid.add(edgeLines);

          const midpoint = start.clone().add(end).multiplyScalar(0.5);
          pyramid.position.copy(midpoint);

          const quaternion = new THREE.Quaternion();
          quaternion.setFromUnitVectors(
            new THREE.Vector3(0, 1, 0),
            direction.normalize()
          );
          pyramid.setRotationFromQuaternion(quaternion);

          pyramid.visible = false;
          scene.add(pyramid);

          boneMeshes.push({ mesh: pyramid, parent: bone, child });
        }
      });
    }
  });

  return boneMeshes;
}

function updateConeSkeleton(boneMeshes) {
  boneMeshes.forEach(({ mesh, parent, child }) => {
    const parentPosition = parent.getWorldPosition(new THREE.Vector3());
    const childPosition = child.getWorldPosition(new THREE.Vector3());

    const direction = new THREE.Vector3().subVectors(childPosition, parentPosition);

    const midpoint = parentPosition.clone().add(childPosition).multiplyScalar(0.5);
    mesh.position.copy(midpoint);

    const quaternion = new THREE.Quaternion();
    quaternion.setFromUnitVectors(new THREE.Vector3(0, 1, 0), direction.normalize());
    mesh.setRotationFromQuaternion(quaternion);

    mesh.scale.set(1, direction.length(), 1);
  });
}

function adjustCameraToFitModel() {
  const box = new THREE.Box3().setFromObject(loadedScene);
  const size = box.getSize(new THREE.Vector3());
  const center = box.getCenter(new THREE.Vector3());
  const distance = size.length() * 1.5;

  const startPosition = camera.position.clone();
  const endPosition = new THREE.Vector3(center.x, center.y, center.z + distance);

  const duration = 1.0; // Animation duration in seconds
  const startTime = clock.getElapsedTime();

  function animate() {
    const elapsedTime = clock.getElapsedTime() - startTime;
    const progress = Math.min(elapsedTime / duration, 1);

    // Smooth easing
    const easeProgress = 1 - Math.pow(1 - progress, 3);

    camera.position.lerpVectors(startPosition, endPosition, easeProgress);
    controls.target.lerp(center, easeProgress);

    if (progress < 1) {
      requestAnimationFrame(animate);
    }
  }

  animate();
}

function formatTime(seconds) {
  const secs = Math.floor(seconds % 60);
  const ms = Math.floor((seconds % 1) * 100);
  return `${secs.toString().padStart(2, "0")}:${ms.toString().padStart(2, "0")}`;
}

function animate() {
  requestAnimationFrame(animate);
  const delta = clock.getDelta();

  if (isAnimationPlaying.value) {
    mixers.forEach((mixer) => mixer.update(delta));
  } else {
    mixers.forEach((mixer) => mixer.update(0));
  }

  if (skeletonMesh) {
    updateConeSkeleton(skeletonMesh);
  }

  if (animationActions.length > 0) {
    const currentMixerTime = animationActions[0].time % animationDetails.duration;
    animationDetails.currentTime = currentMixerTime;
    progressBarWidth.value = (currentMixerTime / animationDetails.duration) * 100;
  }

  controls.update();
  renderer.render(scene, camera);
}

function zoomIn() {
  const direction = new THREE.Vector3()
    .subVectors(camera.position, controls.target)
    .normalize();
  const targetPosition = camera.position.clone().addScaledVector(direction, -zoomStep);
  initiateZoomTransition(targetPosition);
}

function zoomOut() {
  const direction = new THREE.Vector3()
    .subVectors(camera.position, controls.target)
    .normalize();
  const targetPosition = camera.position.clone().addScaledVector(direction, zoomStep);
  initiateZoomTransition(targetPosition);
}

function initiateZoomTransition(targetPosition) {
  const startPosition = camera.position.clone();
  const duration = 0.5;
  const startTime = clock.getElapsedTime();

  function zoomTransition() {
    const elapsedTime = clock.getElapsedTime() - startTime;
    const progress = Math.min(elapsedTime / duration, 1);

    camera.position.lerpVectors(startPosition, targetPosition, progress);
    controls.update();

    if (progress < 1) {
      requestAnimationFrame(zoomTransition);
    }
  }

  zoomTransition();
}

function togglePanMode() {
  isPanModeEnabled.value = !isPanModeEnabled.value;
  controls.mouseButtons = {
    LEFT: THREE.MOUSE.ROTATE,
    MIDDLE: THREE.MOUSE.DOLLY,
    RIGHT: THREE.MOUSE.PAN,
  };

  if (isPanModeEnabled.value) {
    controls.mouseButtons.LEFT = THREE.MOUSE.PAN;
    controls.mouseButtons.RIGHT = THREE.MOUSE.ROTATE;
  }

  controls.update();
}

function toggleSkeletonVisibility() {
  if (skeletonMesh) {
    skeletonMesh.forEach(({ mesh }) => {
      mesh.visible = !mesh.visible;
    });
  }

  if (loadedScene) {
    loadedScene.visible = !loadedScene.visible;
  }

  controls.update();
}

function toggleAnimation() {
  isAnimationPlaying.value = !isAnimationPlaying.value;
  iconName.value = isAnimationPlaying.value ? "pause" : "play";

  animationActions.forEach((action) => {
    action.paused = !isAnimationPlaying.value;
    if (!isAnimationPlaying.value) {
      action.play();
    }
  });

  if (!isAnimationPlaying.value) {
    currentFrame.value = Math.round(animationDetails.currentTime * 30);
  }
}

function addGridFloor() {
  const gridHelper = new THREE.GridHelper(500, 50, 0x555555, 0x444444);
  gridHelper.position.y = 0;
  gridHelper.material.opacity = 0.75;
  gridHelper.material.transparent = true;
  scene.add(gridHelper);
}

function resetCamera() {
  adjustCameraToFitModel();
}

function setAnimationProgress(event) {
  const bounds = event.currentTarget.getBoundingClientRect();
  const clickX = event.clientX - bounds.left;
  const progressPercentage = clickX / bounds.width;
  const newTime = progressPercentage * animationDetails.duration;

  animationActions.forEach((action) => {
    action.paused = true;
    action.time = newTime;
    action.play();
    action.paused = !isAnimationPlaying.value;
  });

  animationDetails.currentTime = newTime;
  progressBarWidth.value = progressPercentage * 100;
}

function startDragging(event) {
  dragging.value = true;
  updateAnimationProgress(event);
}

function dragUpdate(event) {
  if (dragging.value) {
    updateAnimationProgress(event);
  }
}

function stopDragging() {
  dragging.value = false;
}

function updateAnimationProgress(event) {
  const bounds = event.currentTarget.getBoundingClientRect();
  const clickX = event.clientX - bounds.left;
  const progressPercentage = clickX / bounds.width;
  const newTime = progressPercentage * animationDetails.duration;

  animationActions.forEach((action) => {
    action.paused = true;
    action.time = newTime;
    action.play();
    action.paused = !isAnimationPlaying.value;
  });

  animationDetails.currentTime = newTime;
  progressBarWidth.value = progressPercentage * 100;
}

defineExpose({
  setPlaybackSpeed,
  resetCamera,
  toggleSkeletonVisibility,
});
</script>

<style scoped>
.anim-controls,
.scale-controls {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.control-btn {
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-bar {
  background-color: white;
  height: 0.7rem;
  width: 0%;
  min-width: 4px;
}

.animation-wrapper {
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.threejs-container {
  margin: auto;
  width: 90%;
  height: 70vh;
}

@media (max-height: 750px) {
  .threejs-container {
    margin: auto;
    width: 85%;
    height: 60vh;
  }
}

@media (max-width: 980px) {
  .threejs-container {
    width: 85%;
  }
}

.animation-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.playbar {
  width: 40%;
}

.progress-container {
  flex-grow: 1;
  height: 0.7rem;
  background-color: darkgrey;
  border-radius: 4px;
  margin: 0 10px;
  position: relative;
  cursor: pointer;
  margin-bottom: 5px;
  user-select: none;
}

.time-codes {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.playbar-controls {
  display: flex;
  align-items: center;
  justify-content: center;
}

.playbar-controls .control-btn:not(:first-child) {
  margin-left: 0.7rem;
}

.locked {
  cursor: not-allowed;
}

.rotate {
  transform: rotate(180deg);
}
</style>
