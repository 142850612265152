<template>
  <div class="input-element">
    <base-icon v-if="icon" :icon-name="icon" color="#FFFFFF66"></base-icon>

    <input
      class="body-text"
      :value="modelValue"
      :type="inputType"
      :placeholder="placeholder"
      :required="required"
      :id="type"
      @input="updateValue"
      @change="event => emit('update:modelValue', event.target.value)"
      @blur="event => emit('update:modelValue', event.target.value)"
    />

    <base-icon
      v-if="type === 'password'"
      :icon-name="isPasswordVisible ? 'eyeClosed' : 'eyeOpened'"
      class="toggle-password"
      @click="togglePasswordVisibility"
    ></base-icon>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import BaseIcon from "@/components/BaseIcon.vue";

const props = defineProps({
  type: {
    type: String,
    default: "text",
  },
  modelValue: {
    type: String,
  },
  required: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: "",
  },
  icon: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["update:modelValue"]);

const debounce = (callback, wait) => {
  let timeout;
  return function (...args) {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      callback(...args);
    }, wait);
  };
};

const updateValue = debounce((event) => emit("update:modelValue", event.target.value), 500);

const isPasswordVisible = ref(false);

const inputType = computed(() => {
  return props.type === "password" && isPasswordVisible.value ? "text" : props.type;
});

const togglePasswordVisibility = () => {
  isPasswordVisible.value = !isPasswordVisible.value;
};
</script>

<style scoped>
.input-element {
  display: flex;
  align-items: center;
  gap: 8px;
  border: 2px solid #FFFFFF1A;
  border-radius: 5px;
  margin-bottom: 12px;
  transition: border ease-in-out 300ms;
  padding: 0.5rem;
  position: relative;
}

.input-element:hover {
  border: 2px solid #FFFFFF66;
}

.input-element:has(input:focus-visible) {
  border: 2px solid #FFFFFFB2;
}

.input-element input {
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 0.5rem;
}

.input-element input:focus-visible {
  outline: 0;
}

.input-element input:-webkit-autofill {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}

.toggle-password {
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.toggle-password:hover {
  opacity: 0.7;
}
</style>