<template>
  <div class="profile-container">
    <h1 class="header-1">Account</h1>
    <p class="description">Manage your settings and take charge of your account</p>

    <div v-if="isLoading" class="loading-state">
      <div class="loading-spinner"></div>
      <p>Loading account details...</p>
    </div>

    <div v-else-if="error" class="error-state">
      <BaseIcon iconName="alert" class="error-icon" />
      <h3>Failed to load account details</h3>
      <p>{{ error }}</p>
      <button class="retry-btn" @click="retryLoading">Try Again</button>
    </div>

    <div v-else-if="!userDetails" class="error-state">
      <BaseIcon iconName="alert" class="error-icon" />
      <h3>No Account Details Found</h3>
      <p>Unable to retrieve your account information.</p>
      <button class="retry-btn" @click="retryLoading">Try Again</button>
    </div>

    <div v-else class="content">
      <div class="account-details">
        <div class="detail">
          <BaseIcon iconName="person" class="icon" color="rgba(255, 255, 255, 0.4)" />
          <div class="detail-info">
            <div class="label">Name</div>
            <div class="value">
              <NameEditor :modelValue="userDetails.user_name" @save="handleNameChange" />
            </div>
          </div>
        </div>

        <div class="detail">
          <BaseIcon iconName="envelop" class="icon" color="rgba(255, 255, 255, 0.4)" />
          <div class="detail-info">
            <div class="label">Email</div>
            <div class="value">{{ email }}</div>
          </div>
        </div>

        <div class="detail">
          <BaseIcon iconName="credit" class="icon" color="rgba(255, 255, 255, 0.4)" />
          <div class="detail-info">
            <div class="label">Credits</div>
            <div class="value">{{ remainingCredits }} out of {{ maxCredits }}</div>
          </div>
        </div>

        <div
          class="detail"
          v-if="userDetails.expiry_date && userDetails.expiry_date !== 'None'"
        >
          <BaseIcon iconName="calendar" class="icon" color="rgba(255, 255, 255, 0.4)" />
          <div class="detail-info">
            <div class="label">Next Billing Date</div>
            <div class="value">{{ formatExpiryDate(userDetails.expiry_date) }}</div>
          </div>
        </div>

        <div class="detail">
          <BaseIcon iconName="lock" class="icon" color="rgba(255, 255, 255, 0.4)" />
          <div class="detail-info">
            <div class="label">Password</div>
            <div class="value">
              <SecondBtn @click="handleResetPassword" :disabled="isResettingPassword">
                {{ isResettingPassword ? "Sending..." : "Reset password" }}
              </SecondBtn>
            </div>
          </div>
        </div>
      </div>

      <!-- Right: Plan Summary -->
      <div class="plan-details">
        <h2 class="header-2">Plan Summary</h2>
        <PlanSummary
          :planName="currentPlan.displayName"
          :status="getSubscriptionStatus"
          :subscriptionId="userDetails.subscription_id"
          :expiryDate="userDetails.expiry_date"
        />
      </div>
    </div>

    <!-- Actions: Upgrade, Logout, Cancel -->
    <div class="actions" v-if="userDetails">
      <router-link to="/plans">
        <PrimaryBtn class="upgrade-btn">Upgrade Plan</PrimaryBtn>
      </router-link>
      <div class="bottom-actions">
        <button class="logout-btn" @click="handleLogout">Log Out</button>
        <button
          class="cancel-btn"
          @click="handleCancelSubscription"
          v-if="userDetails.subscription_id !== 'None'"
        >
          Cancel Subscription
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import PrimaryBtn from "@/components/PrimaryBtn.vue";
import BaseIcon from "@/components/BaseIcon.vue";
import PlanSummary from "@/components/PlanSummary.vue";
import NameEditor from "@/components/NameEditor.vue";
import SecondBtn from "@/components/SecondBtn.vue";
import { plans, subscriptionPlanMap } from "@/constants/plans";
import { userAPI } from "@/services/api";
import { authAPI } from "@/services/api";

const store = useStore();
const router = useRouter();
const isLoading = ref(true);
const isResettingPassword = ref(false);
const error = ref(null);

async function loadUserData() {
  isLoading.value = true;
  error.value = null;
  try {
    await Promise.all([
      store.dispatch("fetchUserDetails"),
      store.dispatch("fetchRemainingCredits"),
    ]);
  } catch (err) {
    error.value = err.message || "Failed to load account details";
    console.error("Error loading user data:", err);
  } finally {
    isLoading.value = false;
  }
}

async function retryLoading() {
  await loadUserData();
}

onMounted(() => {
  loadUserData();
});

const userDetails = computed(() => store.getters.getUserDetails);
const email = computed(() => store.getters.getEmail);

const currentPlan = computed(() => {
  const currentPlanName = userDetails.value?.current_subscription_plan || "Freemium";
  const mappedPlanKey = subscriptionPlanMap[currentPlanName];
  const planDetails = plans[mappedPlanKey || "Freemium"];

  return {
    ...planDetails,
    displayName: mappedPlanKey,
  };
});

const maxCredits = computed(() => {
  const planKey =
    subscriptionPlanMap[userDetails.value?.current_subscription_plan] || "Freemium";
  return plans[planKey]?.creditsByDefault?.toLocaleString() || "0";
});

const remainingCredits = computed(() => {
  const credits = store.getters.getRemainingCredits;
  return Number(credits).toLocaleString();
});

const getSubscriptionStatus = computed(() => {
  if (!userDetails.value) return "Unknown";
  if (!userDetails.value.current_subscription_plan) return "Free Plan";
  if (
    !userDetails.value.expiry_date ||
    userDetails.value.expiry_date === "None" ||
    userDetails.value.expiry_date === null
  )
    return "Inactive";
  const expiryDate = new Date(userDetails.value.expiry_date);
  return expiryDate > new Date() ? "Active" : "Expired";
});

const formatExpiryDate = (dateString) => {
  if (!dateString || dateString === "None") return "N/A";
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

const handleLogout = () => {
  store.dispatch("logout");
};

const handleCancelSubscription = () => {
  router.push("/subcancel");
};

async function handleNameChange(newName) {
  try {
    await userAPI.changeUserName(store.getters.getSessionId, newName);
    await loadUserData();
  } catch (error) {
    console.error("Error updating name:", error);
  }
}

const handleResetPassword = async () => {
  try {
    isResettingPassword.value = true;
    await authAPI.resetPasswordEmail(email.value);
    router.push({
      path: "/resetpass/confirm",
      query: { fromProfile: "true" },
    });
  } catch (error) {
    console.error("Error requesting password reset:", error);
  } finally {
    isResettingPassword.value = false;
  }
};
</script>

<style scoped>
.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  width: 80%;
  max-width: 900px;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.description {
  color: rgba(255, 255, 255, 0.6);
  margin: 0;
  padding: 0 35%;
}

.content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 40px;
  text-align: left;
}

.account-details,
.plan-details {
  flex: 1;
  padding: 20px;
}

.detail {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 8px;
  min-height: 64px;
}

.detail-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.label {
  color: rgba(255, 255, 255, 0.4);
  font-size: 0.9rem;
  line-height: 1.2;
}

.value {
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  line-height: 1.2;
}

.actions {
  width: 100%;
  margin-top: 30px;
  text-align: center;
}

.upgrade-btn {
  width: 200px;
}

.bottom-actions {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  margin-top: 20px;
}

.logout-btn,
.cancel-btn {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.4);
  cursor: pointer;
  font-size: 14px;
  transition: color 0.3s;
  padding: 8px 16px;
  border-radius: 4px;
}

.cancel-btn {
  position: absolute;
  right: 0;
}

.logout-btn:hover {
  color: #ff4444;
  background: rgba(255, 68, 68, 0.1);
}

.cancel-btn:hover {
  color: #ff4444;
  background: rgba(255, 68, 68, 0.1);
}

.loading-state,
.error-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  text-align: center;
  border-radius: 12px;
  margin: 20px 0;
  width: 100%;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top-color: #20ad8b;
  border-right-color: #ace41b;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

.error-icon {
  font-size: 32px;
  color: #ff4444;
  margin-bottom: 16px;
}

.retry-btn {
  margin-top: 16px;
  padding: 8px 24px;
  border-radius: 8px;
  border: none;
  background: linear-gradient(135deg, #20ad8b 0%, #ace41b 100%);
  color: white;
  cursor: pointer;
  font-weight: 500;
  transition: opacity 0.3s;
}

.retry-btn:hover {
  opacity: 0.9;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.reset-password-btn {
  background: none;
  border: none;
  color: #20ad8b;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
  font-weight: 500;
  transition: opacity 0.3s;
}

.reset-password-btn:hover {
  opacity: 0.8;
}

@media (max-width: 850px) {
  .content {
    flex-direction: column;
  }

  .description {
    padding: 0 10%;
  }

  .profile-container {
    margin: 2rem 0;
  }
}
</style>
