<template>
  <div class="plan-card" :class="{ active: isActive }">
    <div v-if="isActive" class="active-badge">
      Current Plan
    </div>
    <div class="plan-card__info">
      <p class="plan-card__title">{{ planTitle }}</p>

      <p class="plan-card__price header-2">{{ displayPrice }}</p>

      <p class="plan-card__description caption">{{ planDescription }}</p>
    </div>

    <primary-btn
      @click="onSelectPlan"
      :disabled="isActive || planTitle == 'Freemium'"
    >
      {{ btnLabel }}
    </primary-btn>

    <ul class="plan-card__features caption" v-if="features.length">
      <li
        class="plan-card__feature-item"
        v-for="(feature, index) in features"
        :key="index"
      >
        {{ feature }}
      </li>
    </ul>
  </div>
</template>

<script setup>
import PrimaryBtn from "@/components/PrimaryBtn.vue";
import { defineProps, defineEmits, computed } from "vue";

const props = defineProps({
  planTitle: {
    type: String,
    required: true,
  },
  planDescription: {
    type: String,
    default: "",
  },
  priceMonthly: {
    type: [Number, String],
    required: true,
  },
  priceAnnual: {
    type: [Number, String],
    required: true,
  },
  isAnnual: {
    type: Boolean,
    default: false,
  },
  features: {
    type: Array,
    default: () => [],
  },
  btnLabel: {
    type: String,
    required: true,
  },
  isActive: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["selectPlan"]);

const displayPrice = computed(() => {
  const currentPrice = props.isAnnual ? props.priceAnnual : props.priceMonthly;
  if (typeof currentPrice === "number") {
    return `$${currentPrice} / month`;
  }
  return currentPrice;
});

function onSelectPlan() {
  if (!props.isActive) {
    emit("selectPlan", {
      title: props.planTitle,
      price: props.isAnnual ? props.priceAnnual : props.priceMonthly,
      description: props.planDescription,
      features: props.features,
      paypalPlanId: props.paypalPlanId
    });
  }
}
</script>

<style scoped>
.plan-card {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  padding: 16px 20px;
  position: relative;
}

.plan-card__info {
  height: 35%;
}

.plan-card__title {
  color: rgba(255, 255, 255, 0.7);
}

.plan-card__price {
  margin-bottom: 8px;
}

.plan-card__description {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 16px;
  font-size: .8rem;
}

.plan-card__features {
  list-style: disc;
  padding: 0;
  padding-left: 20px;
  margin-bottom: 16px;
}

.plan-card__feature-item {
  text-align: left;
  margin: 4px 0;
  color: rgba(255, 255, 255, 0.7);
}

.plan-card__button {
  width: 100%;
  padding: 12px;
  background-color: #5f77ff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.plan-card__button:hover {
  background-color: #4355c8;
}

.active-badge {
  position: absolute;
  top: -12px;
  right: -12px;
  background: linear-gradient(135deg, #20AD8B 0%, #ACE41B 100%);
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.8rem;
  font-weight: 500;
}

.plan-card.active {
  position: relative;
  border: none;
}

.plan-card.active::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 24px;
  padding: 2px;
  background: linear-gradient(135deg, #20AD8B 0%, #ACE41B 100%);
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}
</style>
