<template>
  <div class="project-item" @click="goToAnimationDetail">
    <div class="status-indicators">
      <div v-if="!viewed" class="new-tag">NEW</div>
      <div v-if="isShowFavoriteStar" class="favorite-btn" @click.stop="toggleFavorite">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          :class="{ filled: isFavoriteLocal, outlined: !isFavoriteLocal }"
        >
          <path
            d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
            :fill="isFavoriteLocal ? '#FFD700' : 'none'"
            :stroke="isFavoriteLocal ? '#FFD700' : '#FFFFFF'"
            stroke-width="1.5"
          />
        </svg>
      </div>
    </div>

    <div class="preview-wrapper">
      <img :src="finalImgUrl" :alt="imgName" class="preview-image" />
      <div v-if="isProcessing" class="processing-overlay">
        <div class="spinner">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
        <span>Processing...</span>
      </div>
      <div v-if="hasFailed" class="failed-overlay">
        <div class="error-icon">
          <span>!</span>
        </div>
        <span>Failed</span>
      </div>
    </div>

    <div class="header-text caption" :title="imgName">
      {{ truncatedName }}
    </div>

    <div class="date-text caption">
      {{ relativeDate }}
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { computed, ref, onMounted, watch } from "vue";
import defaultImage from "@/assets/project-placeholder.png";
import { useStore } from "vuex";
import { animationAPI } from "@/services/api";

const store = useStore();
const sessionId = store.getters.getSessionId;
const router = useRouter();

const emit = defineEmits(["processingStatusChange"]);

const props = defineProps({
  imgUrl: {
    type: String,
    default: defaultImage,
  },
  imgName: {
    type: String,
    required: false,
  },
  imgDate: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  isShowFavoriteStar: {
    type: Boolean,
    default: true,
  },
  isFavorite: {
    type: Boolean,
    default: false,
  },
  viewed: {
    type: Boolean,
    default: true,
  },
  animationStatus: {
    type: String,
    default: "True",
  },
});

const finalImgUrl = computed(() => props.imgUrl || defaultImage);

const isFavoriteLocal = ref(props.isFavorite);

const isProcessing = computed(
  () => props.animationStatus === "Started" || props.animationStatus === "None"
);
const hasFailed = computed(() => props.animationStatus === "False");

watch(isProcessing, (newValue) => {
  emit("processingStatusChange", {
    id: props.id,
    isProcessing: newValue,
  });
});

onMounted(() => {
  isFavoriteLocal.value = props.isFavorite;
  if (isProcessing.value) {
    emit("processingStatusChange", {
      id: props.id,
      isProcessing: true,
    });
  }
});

const truncatedName = computed(() => {
  const maxLength = 20;
  if (!props.imgName) return "";
  return props.imgName.length > maxLength
    ? props.imgName.slice(0, maxLength) + "..."
    : props.imgName;
});

async function toggleFavorite() {
  try {
    const result = await animationAPI.toggleFavorite(sessionId, props.id);
    if (result.message) {
      isFavoriteLocal.value = !isFavoriteLocal.value;
    } else {
      console.error("Failed to update favorite status");
    }
  } catch (error) {
    console.error("Error setting favorite:", error);
  }
}

const relativeDate = computed(() => {
  if (props.imgDate === "None") return "No date";

  const imgDate = new Date(props.imgDate + "Z");
  const now = new Date();

  const diffTime = (now - imgDate) / 1000;
  const formatter = new Intl.RelativeTimeFormat("en", { numeric: "auto" });

  if (diffTime < 60) return formatter.format(-Math.round(diffTime), "seconds");
  if (diffTime < 3600) return formatter.format(-Math.round(diffTime / 60), "minutes");
  if (diffTime < 86400) return formatter.format(-Math.round(diffTime / 3600), "hours");
  return formatter.format(-Math.round(diffTime / 86400), "days");
});

const goToAnimationDetail = () => {
  router.push({ path: "/animation", query: { animationId: props.id } });
};
</script>

<style scoped>
.project-item {
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  box-sizing: border-box;
  aspect-ratio: 4/3;
  width: 250px;
  min-width: 250px;
}

.preview-wrapper {
  position: relative;
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 10px;
  margin-bottom: 8px;
  box-sizing: border-box;
  overflow: hidden;
  min-height: 160px;
}

.preview-image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
  border-radius: 4px;
}

.header-text {
  width: 100%;
  height: 20px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.9rem;
  line-height: 20px;
  padding: 0 4px;
  box-sizing: border-box;
  margin-bottom: 4px;
}

.date-text {
  width: 100%;
  height: 16px;
  color: rgba(255, 255, 255, 0.2);
  text-align: left;
  font-size: 0.8rem;
  line-height: 16px;
  padding: 0 4px;
  box-sizing: border-box;
}

.status-indicators {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 8px;
  align-items: center;
  z-index: 2;
}

.favorite-btn {
  display: none;
  cursor: pointer;
}

.new-tag {
  background: linear-gradient(135deg, #20ad8b 0%, #ace41b 100%);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.project-item:hover .favorite-btn {
  display: block;
}

svg.filled path {
  fill: #ffd700;
  stroke: #ffd700;
}

svg.outlined path {
  fill: none;
  stroke: #ffffff;
}

.processing-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 16px;
  gap: 12px;
}

.processing-overlay span {
  color: rgba(255, 255, 255, 0.7);
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: linear-gradient(135deg, #20ad8b 0%, #ace41b 100%);
  animation: spin 1.5s infinite;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

.dot:nth-child(4) {
  animation-delay: 0.6s;
}

@keyframes spin {
  0%,
  100% {
    transform: scale(0.3);
    opacity: 0.3;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
}

.processing-spinner {
  display: none;
}

.failed-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 16px;
  gap: 12px;
}

.failed-overlay span {
  color: rgba(255, 255, 255, 0.7);
}

.error-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ff4d4d;
  font-size: 24px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .project-item {
    width: 200px;
    min-width: 200px;
  }

  .preview-wrapper {
    min-height: 140px;
  }

  .header-text,
  .date-text {
    font-size: 1.2rem;
  }
}
</style>
