<template>
  <router-view></router-view>
</template>

<script setup>
</script>

<style>
body {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    color: #fff;
    background-color: #121112;
}

.body-text {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    color: #fff;
}

.header-1 { 
    font-family: 'Inter', sans-serif;
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    color: #fff;
}

.header-2 {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    line-height: 26px;
    font-weight: 500;
    color: #fff;
}

.caption {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #fff;
}

.description {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    color: #fff;
}

.tag {
    font-family: 'Inter', sans-serif;
    font-size: 8px;
    line-height: 8px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
}

.control-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 3px;
    background-color: rgba(255, 255, 255, .1);
    border-radius: 8px;
    cursor: pointer;
}

button {
    background: none;       /* Removes any background properties */
    border: none;           /* Removes border */
    color: inherit;         /* Inherits color from parent */
    font: inherit;          /* Inherits font properties from parent */
    line-height: normal;    /* Resets line-height to a normal value */
    overflow: visible;      /* Ensures the content is not clipped */
    padding: 0;             /* Removes padding */
    -webkit-appearance: none; /* Removes platform-native styling on iOS */
    -moz-appearance: none;    /* Removes platform-native styling on Firefox */
    appearance: none;         /* Removes platform-native styling */
    cursor: pointer;         /* Ensures the cursor appears as a pointer */
    align-items: flex-start; /* Align items at the start in flex layouts */
    text-align: start;       /* Aligns text to the start */
    text-transform: none;    /* No text transformation */
    white-space: nowrap;     /* Prevents wrapping of text */
    background-color: transparent; /* Transparent background */
    border-radius: 0;        /* No border radius */
}

button:focus {
    outline: none; /* Removes outline on focus, consider accessibility implications */
}

button:active,
button:hover {
    background-color: transparent; /* Ensures no background change on hover/active */
}

.gradient-text {
  background: linear-gradient(99.6deg, #20AD8B 11.39%, #ACE41B 86.3%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline;
}

.gray-text {
    color: rgba(255, 255, 255, 0.7);
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}

/* Firefox scrollbar styling */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.2) rgba(255, 255, 255, 0.1);
}
</style>
