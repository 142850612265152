export function simulateLoading(isLoading, hasError, loadingProgress) {
    isLoading.value = !isLoading.value;
    if (isLoading.value) {
        hasError.value = false;
        // Simulate progress
        let progress = 0;
        const interval = setInterval(() => {
            progress += 1;
            loadingProgress.value = progress;
            if (progress >= 100) clearInterval(interval);
        }, 50);
    }
}

export function simulateError(hasError, isLoading, errorType, type) {
    hasError.value = true;
    isLoading.value = false;
    errorType.value = type;
}

export function resetState(hasError, isLoading, loadingProgress, errorType) {
    hasError.value = false;
    isLoading.value = false;
    loadingProgress.value = 0;
    errorType.value = '';
} 