// Subscription plan mapping
export const subscriptionPlanMap = {
  'Monthly Standard': 'Basic',
  'Annual Standard': 'Basic',
  'Monthly Pro': 'Pro',
  'Annual Pro': 'Pro',
  'Freemium': 'Freemium',
  'Enterprise': 'Enterprise'
};

export const plans = {
  Freemium: {
    title: "Freemium",
    description: "Explore AvaCapo features for free",
    priceMonthly: "Free",
    priceAnnual: "Free",
    creditsByDefault: 300,
    paypalPlanId: {
      monthly: null,
      annual: null
    },
    displayNames: {
      monthly: "Freemium",
      annual: "Freemium"
    },
    features: [
      "300 credits per month",
      "No credit card required",
      "Non-commercial license",
    ],
  },
  Basic: {
    title: "Basic",
    description: "A great start for individuals enhancing productivity with AI",
    priceMonthly: 20,
    priceAnnual: 16,
    creditsByDefault: 900,
    paypalPlanId: {
      monthly: "P-2UW853875B7306247M7DQIJI",
      annual: "P-4TC51062MR105871XM7DQHCI"
    },
    displayNames: {
      monthly: "Monthly Standard",
      annual: "Annual Standard"
    },
    features: [
      "900 credits per month",
      "General commercial terms"
    ],
  },
  Pro: {
    title: "Pro",
    description: "All features for professionals to boost efficiency",
    priceMonthly: 100,
    priceAnnual: 80,
    creditsByDefault: 6000,
    paypalPlanId: {
      monthly: "P-78N035985L298241YM7DQHXA",
      annual: "P-72S72037CT989103CM7DQEHI"
    },
    displayNames: {
      monthly: "Monthly Pro",
      annual: "Annual Pro"
    },
    features: [
      "6 000 credits per month",
      "Faster processing",
      "Yearly access to beta-features",
      "Individual support",
      "General commercial terms",
    ],
  },
  Enterprise: {
    title: "Enterprise",
    description: "Custom solutions for organisations at scale",
    priceMonthly: "Individual Terms",
    priceAnnual: "Individual Terms",
    creditsByDefault: null,
    paypalPlanId: {
      monthly: null,
      annual: null
    },
    displayNames: {
      monthly: "Enterprise",
      annual: "Enterprise"
    },
    features: [
      "Customize your subscription according to your needs",
      "Faster processing",
      "Share data between team members",
      "Individual support",
      "General commercial terms",
    ],
  }
}; 