<template>
  <div class="section">
    <div class="center-section">
      <div class="form-heading">
        <div class="header-1">
          <span v-if="isConfirmed">
            <BaseIcon
              iconName="check"
              color="#20AD8B"
              width="32"
              height="32"
              class="status-icon"
            />
            Thank you for confirming your email!
          </span>

          <span v-else-if="isError">
            <BaseIcon
              iconName="error"
              color="#ff6b6b"
              width="32"
              height="32"
              class="status-icon"
            />
            {{ errorMessage }}
          </span>
          <span v-else>
            <div class="loading-text">
              <BaseIcon iconName="rotate" class="rotating" width="32" height="32" />
              Confirming your email...
            </div>
          </span>
        </div>
      </div>

      <div class="message-box" :class="{ success: isConfirmed, error: isError }">
        <p v-if="isConfirmed" class="caption">
          Your email has been confirmed successfully. You can now log in to your account.
        </p>
        <p v-else-if="isError" class="caption">
          {{ errorDetails }}
        </p>
      </div>

      <div class="action-link caption">
        <router-link v-if="isConfirmed" to="/login" class="link">
          <BaseIcon iconName="chevronRight" class="icon-inline" />
          Continue to Login
        </router-link>
        <button
          v-else-if="isError && route.query.email"
          @click="resendVerificationLink"
          class="link button-link"
          :disabled="isResending"
        >
          <BaseIcon
            :iconName="isResending ? 'rotate' : 'refresh'"
            class="icon-inline"
            :class="{ rotating: isResending }"
          />
          {{ isResending ? "Sending..." : "Request New Confirmation Link" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import BaseIcon from "@/components/BaseIcon.vue";
import { authAPI } from "@/services/api";

const isConfirmed = ref(false);
const isError = ref(false);
const errorMessage = ref("");
const errorDetails = ref("");
const route = useRoute();
const isResending = ref(false);
const router = useRouter();

async function resendVerificationLink() {
  isResending.value = true;
  try {
    await authAPI.resendVerification(route.query.email);
    isError.value = false;
    router.push({
      path: "/signup/success",
      query: { email: route.query.email },
    });
  } catch (error) {
    console.error("Failed to resend verification:", error);
    errorDetails.value = "Failed to send new verification link. Please try again later.";
  } finally {
    isResending.value = false;
  }
}

onMounted(async () => {
  const token = route.params.token;

  if (!token) {
    isError.value = true;
    errorMessage.value = "Invalid confirmation link";
    errorDetails.value =
      "The confirmation link appears to be invalid or incomplete. Please check your email for the correct link.";
    return;
  }

  try {
    const response = await authAPI.confirmEmail(token);

    if (response.status === 202) {
      isConfirmed.value = true;
    } else {
      throw response;
    }
  } catch (error) {
    isError.value = true;

    if (error.status === 404) {
      errorMessage.value = "Link not found or already used";
      errorDetails.value =
        "This confirmation link has expired or has already been used. Please request a new confirmation link.";
    } else if (error.status === 406) {
      errorMessage.value = "Link has expired";
      errorDetails.value =
        "Your confirmation link has expired. Please request a new confirmation link to verify your email.";
    } else {
      errorMessage.value = "Verification failed";
      errorDetails.value =
        "We encountered an issue while confirming your email. Please try again or request a new confirmation link.";
    }
  }
});
</script>

<style scoped>
.section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.center-section {
  width: 35%;
  min-width: 300px;
  text-align: center;
}

.form-heading {
  margin-bottom: 24px;
}

.form-heading .header-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.message-box {
  padding: 24px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  margin-bottom: 24px;
}

.message-box.success {
  background-color: rgba(32, 173, 139, 0.1);
}

.message-box.error {
  background-color: rgba(255, 107, 107, 0.1);
}

.message-box .caption {
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.5;
}

.action-link {
  text-align: center;
}

.link {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: white;
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.1);
  transition: background-color 0.2s;
}

.link:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.loading-text {
  display: flex;
  align-items: center;
  gap: 12px;
}

.rotating {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.icon-inline {
  width: 20px;
  height: 20px;
}

@media (max-width: 768px) {
  .center-section {
    width: 90%;
  }
}

.button-link {
  border: none;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  background: none;
}

.button-link:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
</style>
