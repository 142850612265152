<template>
  <div class="timeline-editor">
    <div class="video-section">
      <video
        ref="videoElement"
        :src="videoUrl"
        muted
        @loadedmetadata="handleLoadedMetadata"
        @timeupdate="handleVideoTimeUpdate"
      />
      <div class="animation-options">
        <AnimationNameEditor v-model="animationName" />

        <div class="checkboxes">
          <div class="toggle-line" v-for="(value, key) in toggles" :key="key">
            <span>{{ toggleLabels[key] }}</span>
            <ToggleComponent
              v-model="toggles[key]"
              :locked="lockedToggles.includes(key)"
            />
          </div>
        </div>

        <p class="range-info">Selected: {{ selectedDuration.toFixed(2) }} sec</p>
      </div>
    </div>

    <div class="timeline-row">
      <SecondBtn class="play-pause-btn" @click="togglePlay">
        {{ isPlaying ? "⏸" : "⏵" }}
      </SecondBtn>

      <div class="timeline-outer" ref="timelineRef" @click="handleTimelineClick">
        <div class="timeline-inner">
          <div class="frames-strip">
            <div
              v-for="(thumb, i) in thumbnails"
              :key="i"
              class="frame-thumb"
              :style="{ backgroundImage: `url(${thumb.url})` }"
            />
          </div>
        </div>

        <div
          class="active-zone"
          :style="{
            left: getPositionStyle(timeline.startTime),
            width: getSelectionWidthStyle,
          }"
        ></div>

        <div
          class="boundary start-boundary"
          :style="{ left: getPositionStyle(timeline.startTime) }"
          @mousedown.prevent="handleHandleDown('start')"
        ></div>
        <div
          class="boundary end-boundary"
          :style="{ left: getPositionStyle(timeline.endTime) }"
          @mousedown.prevent="handleHandleDown('end')"
        ></div>
        <div
          class="trim-handle current-handle"
          :style="{ left: getPositionStyle(timeline.currentTime) }"
          @mousedown.prevent="handleHandleDown('current')"
        ></div>
      </div>
    </div>

    <div class="action-buttons">
      <PrimaryBtn class="create-animation-btn" @click="handleSubmitClick">
        Create animation
      </PrimaryBtn>
      <SecondBtn @click="$emit('cancel')">Cancel</SecondBtn>
    </div>

    <ProgressSpinner
      v-if="isLoading"
      :progress="loadingProgress"
      :is-processing="isProcessing"
      label="Processing video..."
    />
  </div>
</template>

<script setup>
import { ref, reactive, computed, nextTick, onBeforeUnmount, onMounted } from "vue";
import SecondBtn from "@/components/SecondBtn.vue";
import PrimaryBtn from "@/components/PrimaryBtn.vue";
import ToggleComponent from "@/components/ToggleComponent.vue";
import AnimationNameEditor from "@/components/AnimationNameEditor.vue";
import ProgressSpinner from "@/components/ProgressSpinner.vue";

const props = defineProps({
  videoFile: {
    type: File,
    required: true,
  },
});
const emit = defineEmits(["submit-trim", "cancel"]);

const videoElement = ref(null);
const timelineRef = ref(null);

const isPlaying = ref(false);
const animationName = ref("");
const thumbnails = ref([]);
const dragging = ref(null);
const isLoading = ref(false);
const loadingProgress = ref(0);
const isProcessing = ref(false);

const timeline = reactive({
  duration: 0,
  startTime: 0,
  endTime: 0,
  currentTime: 0,
  timelineWidth: 0,
});

const toggles = reactive({
  footLock: false,
  trackHands: false,
  face: false,
  multiPerson: false,
});

const toggleLabels = {
  footLock: "Foot Lock",
  trackHands: "Track hands",
  face: "Track emotions",
  multiPerson: "Multi-person",
};

const lockedToggles = ["face", "multiPerson"];

const videoUrl = computed(() =>
  props.videoFile ? URL.createObjectURL(props.videoFile) : ""
);

const selectedDuration = computed(() => timeline.endTime - timeline.startTime);

const getSelectionWidthStyle = computed(
  () => `${((timeline.endTime - timeline.startTime) / timeline.duration) * 100}%`
);

const defaultName = computed(() => {
  const fullName = props.videoFile?.name || "Untitled";
  return fullName.replace(/\.[^.]+$/, "");
});

animationName.value = defaultName.value;

const isVerticalVideo = computed(() => {
  if (!videoElement.value) return false;
  return videoElement.value.videoHeight > videoElement.value.videoWidth;
});

function getPositionStyle(time) {
  return `${(time / timeline.duration) * 100}%`;
}

function clampToSelectedRange(time) {
  return Math.min(timeline.endTime, Math.max(time, timeline.startTime));
}

async function handleLoadedMetadata() {
  if (!videoElement.value) return;
  timeline.duration = videoElement.value.duration;
  timeline.startTime = 0;
  timeline.endTime = timeline.duration;
  timeline.currentTime = 0;

  await nextTick();
  if (timelineRef.value) {
    timeline.timelineWidth = timelineRef.value.offsetWidth;
  }

  await generateThumbnails();
}

function handleVideoTimeUpdate() {
  if (!videoElement.value) return;

  timeline.currentTime = clampToSelectedRange(videoElement.value.currentTime);

  if (timeline.currentTime >= timeline.endTime) {
    videoElement.value.pause();
    isPlaying.value = false;
    timeline.currentTime = timeline.startTime;
    videoElement.value.currentTime = timeline.startTime;
  }
}

function togglePlay() {
  if (!videoElement.value) return;
  if (isPlaying.value) {
    videoElement.value.pause();
    isPlaying.value = false;
    return;
  }
  if (
    timeline.currentTime < timeline.startTime ||
    timeline.currentTime >= timeline.endTime
  ) {
    timeline.currentTime = timeline.startTime;
    videoElement.value.currentTime = timeline.startTime;
  }
  isPlaying.value = true;
  videoElement.value.play().catch(() => {});
}

async function generateThumbnails() {
  if (!videoElement.value) return;
  thumbnails.value = [];
  const vid = videoElement.value;
  const stepCount = 10 - 1;
  const stepSec = vid.duration / stepCount;
  const wasPaused = vid.paused;
  const oldTime = vid.currentTime;
  vid.pause();

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  for (let i = 0; i < 10; i++) {
    const t = Math.min(i * stepSec, vid.duration);
    const thumb = await captureThumbAtTime(t, vid, canvas, ctx);
    thumbnails.value.push(thumb);
  }

  vid.currentTime = oldTime;
  if (!wasPaused) {
    vid.play().catch(() => {});
  }
}

function captureThumbAtTime(time, vid, canvas, ctx) {
  return new Promise((resolve) => {
    const onSeeked = () => {
      vid.removeEventListener("seeked", onSeeked);
      canvas.width = vid.videoWidth / 4;
      canvas.height = vid.videoHeight / 4;
      ctx.drawImage(vid, 0, 0, canvas.width, canvas.height);
      resolve({
        time,
        url: canvas.toDataURL("image/jpeg", 0.7),
      });
    };
    vid.addEventListener("seeked", onSeeked);
    vid.currentTime = time;
  });
}

function handleHandleDown(which) {
  if (isPlaying.value && videoElement.value && !videoElement.value.paused) {
    videoElement.value.pause();
  }
  isPlaying.value = false;
  dragging.value = which;
  document.addEventListener("mousemove", handleDragMove);
  document.addEventListener("mouseup", handleDragUp);
}

function handleDragMove(e) {
  if (!dragging.value || !timelineRef.value || !timeline.duration) return;
  const rect = timelineRef.value.getBoundingClientRect();
  let x = e.clientX - rect.left;
  x = Math.max(0, Math.min(x, timeline.timelineWidth));
  const t = (x / timeline.timelineWidth) * timeline.duration;

  if (dragging.value === "start") {
    timeline.startTime = t;
    if (timeline.startTime > timeline.endTime) {
      timeline.endTime = timeline.startTime;
    }
    timeline.currentTime = timeline.startTime;
    if (videoElement.value) {
      videoElement.value.currentTime = timeline.startTime;
    }
  } else if (dragging.value === "end") {
    timeline.endTime = t;
    if (timeline.endTime < timeline.startTime) {
      timeline.startTime = timeline.endTime;
    }
    timeline.currentTime = timeline.endTime;
    if (videoElement.value) {
      videoElement.value.currentTime = timeline.endTime;
    }
  } else if (dragging.value === "current") {
    timeline.currentTime = clampToSelectedRange(t);
    if (videoElement.value) {
      videoElement.value.currentTime = timeline.currentTime;
    }
  }
}

function handleDragUp() {
  dragging.value = null;
  document.removeEventListener("mousemove", handleDragMove);
  document.removeEventListener("mouseup", handleDragUp);
}

function handleTimelineClick(e) {
  if (!videoElement.value || !timelineRef.value) return;
  if (isPlaying.value && !videoElement.value.paused) {
    videoElement.value.pause();
    isPlaying.value = false;
  }
  const rect = timelineRef.value.getBoundingClientRect();
  let x = e.clientX - rect.left;
  x = Math.max(0, Math.min(x, timeline.timelineWidth));
  timeline.currentTime = clampToSelectedRange(
    (x / timeline.timelineWidth) * timeline.duration
  );
  videoElement.value.currentTime = timeline.currentTime;
}

function handleSubmitClick() {
  emit("submit-trim", {
    start_time: timeline.startTime,
    end_time: timeline.endTime,
    foot_lock: toggles.footLock,
    hands: toggles.trackHands,
    face: toggles.face,
    multi_person: toggles.multiPerson,
    name: animationName.value,
  });
}

function updateTimelineWidth() {
  if (timelineRef.value) {
    timeline.timelineWidth = timelineRef.value.offsetWidth;
  }
}

function handleKeyPress(e) {
  if (
    e.code === "Space" &&
    !["INPUT", "TEXTAREA"].includes(document.activeElement.tagName)
  ) {
    e.preventDefault();
    togglePlay();
  }
}

onMounted(() => {
  const resizeObserver = new ResizeObserver(updateTimelineWidth);
  if (timelineRef.value) {
    resizeObserver.observe(timelineRef.value);
  }

  document.addEventListener("keydown", handleKeyPress);

  onBeforeUnmount(() => {
    resizeObserver.disconnect();
    document.removeEventListener("keydown", handleKeyPress);
  });
});
</script>

<style scoped>
.timeline-editor {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  max-width: 100%;
  box-sizing: border-box;
  height: 100%;
  min-height: min-content;
}

.video-section {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  width: 100%;
  flex: 1;
  min-height: 0;
  max-height: calc(100vh - 8rem);
}

.video-section video {
  border-radius: 1rem;
  flex-shrink: 0;
  width: v-bind('isVerticalVideo ? "35%" : "55%"');
  max-width: v-bind('isVerticalVideo ? "35%" : "55%"');
  min-width: 250px;
  height: 100%;
  max-height: 65vh;
  object-fit: contain;
  background: rgba(0, 0, 0, 0.2);
}

.animation-options {
  width: v-bind('isVerticalVideo ? "calc(65% - 1rem)" : "calc(45% - 1rem)"');
  min-width: 250px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex-shrink: 0;
  overflow-y: auto;
  max-height: 100%;
}

.checkboxes {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem 0;
  border-radius: 0.5rem;
}

.range-info {
  font-size: 0.9rem;
  color: #ccc;
  margin: 0;
}

.timeline-row {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 0.5rem;
  flex-shrink: 0;
}

.play-pause-btn {
  min-width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeline-outer {
  position: relative;
  flex: 1 1 auto;
  height: 2.5rem;
  overflow: visible;
  cursor: pointer;
}

.timeline-inner {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 0.4rem;
  background-color: #000;
}

.frames-strip {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background-color: #000;
}

.frame-thumb {
  flex: 1 1 auto;
  background-size: cover;
  background-position: center;
}

.active-zone {
  position: absolute;
  z-index: 1;
  top: -2px;
  height: calc(100% + 4px);
  box-sizing: border-box;
  border: 0.15rem solid #3eaf3f;
  border-radius: 0.4rem;
  background: transparent;
  pointer-events: none;
}

.boundary {
  position: absolute;
  top: 0;
  width: 4px;
  height: 100%;
  background: transparent;
  cursor: col-resize;
  z-index: 9999;
}

.end-boundary {
  transform: translateX(-100%);
}

.trim-handle {
  position: absolute;
  top: 0;
  width: 3px;
  height: 100%;
  cursor: col-resize;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  z-index: 9999;
  transform: translateX(-50%);
}

.current-handle {
  background: red;
}

.action-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
  width: 100%;
}

.create-animation-btn {
  width: 200px;
}

.action-buttons :deep(.second-btn) {
  width: 200px;
}

.toggle-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  padding: 0.5rem 0;
  gap: 1rem;
}

.toggle-line span {
  flex: 1;
  text-align: left;
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.7);
}

@media (max-width: 1024px) {
  .timeline-editor {
    height: auto;
    min-height: auto;
    overflow-y: auto;
    padding: 0.75rem;
  }

  .video-section {
    flex-direction: column;
    max-height: none;
  }

  .video-section video {
    width: 100%;
    max-width: 100%;
    min-width: 0;
    height: auto;
    max-height: 40vh;
    aspect-ratio: 16/9;
  }

  .animation-options {
    width: 100%;
    max-width: 100%;
    min-width: 0;
    max-height: none;
    overflow-y: visible;
  }

  .checkboxes {
    padding: 0.25rem 0;
  }

  .timeline-row {
    margin-bottom: 0.25rem;
  }

  .action-buttons {
    padding: 0.5rem 0;
    gap: 0.5rem;
  }

  .create-animation-btn,
  .action-buttons :deep(.second-btn) {
    width: 180px;
  }
}
</style>
