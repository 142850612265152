<template>
  <div class="animation-name-block">
    <div class="name">
      <input
        v-if="isEditingName"
        ref="nameInput"
        class="name-input"
        v-model="animationName"
        @blur="saveAnimationName"
        @keyup.enter="saveAnimationName"
        autofocus
      />
      <span v-else class="name-label" @click="enableEditing">
        {{ animationName }}
      </span>
      <span class="edit-icon" @click="enableEditing">✎</span>
    </div>
  </div>
</template>

<script setup>
import { ref, nextTick } from "vue";

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue"]);

const nameInput = ref(null);
const isEditingName = ref(false);
const animationName = ref(props.modelValue);

function enableEditing() {
  isEditingName.value = true;
  nextTick(() => {
    nameInput.value?.focus();
  });
}

function saveAnimationName() {
  isEditingName.value = false;
  emit("update:modelValue", animationName.value);
}
</script>

<style scoped>
.animation-name-block {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  min-height: 2.5rem;
}

.name {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 2.5rem;
  width: 100%;
}

.name-label {
  display: inline-block;
  font-weight: bold;
  font-size: 1.5rem;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 2.5rem;
  height: 2.5rem;
}

.name-input {
  width: 100%;
  height: 2.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  border: 1px solid #999;
  border-radius: 0.3rem;
  box-sizing: border-box;
  padding: 0 0.5rem;
  line-height: 2.5rem;
  flex: 1;
  min-width: 0;
}

.edit-icon {
  cursor: pointer;
  font-size: 1.2rem;
  opacity: 0.7;
  transition: opacity 0.3s;
  flex-shrink: 0;
  width: 1.5rem;
  text-align: center;
  line-height: 2.5rem;
}

.edit-icon:hover {
  opacity: 1;
}
</style>
