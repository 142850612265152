<template>
    <div class="cancel-confirm-container">
        <h1 class="header-1">Your subscription has been cancelled</h1>
        <p class="description">
            But you still have access to the platform <br />
            and its features
        </p>

        <PrimaryBtn class="home-btn" @click="goToHome">
            Go to home page
        </PrimaryBtn>
    </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import PrimaryBtn from "@/components/PrimaryBtn.vue";

const router = useRouter();

const goToHome = () => {
    router.push("/");
};
</script>

<style scoped>
.cancel-confirm-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    width: 80%;
    max-width: 600px;
    padding: 40px;
    border-radius: 16px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.header-1 {
    margin-top: 0;
}

.description {
    color: #999;
    margin: 10px 0 30px;
    font-size: 16px;
    line-height: 1.5;
}

.home-btn {
    width: 100%;
    max-width: 250px;
}
</style>
