<template>
    <ProjectList    name="Favorites"
                    :projectItems="projectItems"
                    :isSingleRow="false"
                    :isFavoriteInline="false"
                    :loading="isInitialLoad ? isLoading : false"
                    :error="isError"
                    @retry="() => fetchProjectItems(store.getters.getSessionId)">

    </ProjectList>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { animationAPI } from '@/services/api';
import ProjectList from '@/components/ProjectList.vue';

const store = useStore();
const router = useRouter();
const projectItems = ref([]);
const isLoading = ref(true);
const isError = ref(false);
const isInitialLoad = ref(true);

async function fetchProjectItems(sessionId) {
    if (isInitialLoad.value) {
        isLoading.value = true;
    }
    isError.value = false;

    try {
        const data = await animationAPI.getFavoriteAnimations(sessionId, router);
        projectItems.value = data;
    } catch (error) {
        console.error('Error fetching project items:', error);
        projectItems.value = [];
        isError.value = true;
    } finally {
        if (isInitialLoad.value) {
            isLoading.value = false;
            isInitialLoad.value = false;
        }
    }
}

onMounted(() => {
    const sessionId = store.getters.getSessionId;
    fetchProjectItems(sessionId);
});

</script>

<style scoped>
.actions-group {
    display: flex;
    align-items: center;
    width: 100%;
    height: 20%;
}

.content-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 33%;
}

.content-section-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 20px;
    box-sizing: border-box;
    width: 100%;
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, .1);
    color: rgba(255, 255, 255, .4);
}

.content-section-list p {
    color: rgba(255, 255, 255, .4);
}

.description p.header-2 {
    margin-bottom: 0;
}

.action-item {
    margin-right: 15px;
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, .1);
    box-sizing: border-box;
    border-radius: 15px;
    padding: 15px 20px;
    cursor: pointer;
}

.action-item:hover {
    background-color: rgba(255, 255, 255, .3);
    transition-duration: .5s;
}

.description .caption {
    color: rgba(255, 255, 255, .4);
    width: 90%;
}

.action-item img {
    height: 100%;
    width: auto;
}

.action-btn {
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    color: black;
}
</style>