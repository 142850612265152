<template>
  <div class="viewer-wrapper">
    <header class="anim-view-header">
      <div class="home-btn control-btn" @click="handleHomeButton">
        <BaseIcon iconName="home"></BaseIcon>
      </div>

      <div class="header-content">
        <img src="@/assets/logo.png" alt="Logo" class="logo" />
        <h1 class="header-1">AvaCapo</h1>
      </div>

      <div class="controls">
        <div class="settings-container">
          <div
            class="settings-btn control-btn"
            :class="{ disabled: isLoading || hasError }"
            @click="handleOpenSettings"
          >
            <BaseIcon iconName="settings"> </BaseIcon>
          </div>

          <div v-if="showSettingsDropdown" @click="handleCloseSettings">
            <div class="modal-window" @click.stop>
              <div v-if="!isLoading" class="animation-details">
                <div class="modal-input">
                  <div class="modal-label">Name</div>
                  <AnimationNameEditor
                    v-model="animationName"
                    @update:modelValue="saveAnimationName"
                  />
                </div>

                <div v-if="promt" class="modal-input">
                  <div class="modal-label">Prompt</div>
                  <div class="prompt">
                    <span>{{ promt }}</span>
                  </div>
                </div>

                <div class="modal-input">
                  <div class="modal-label">Favorite</div>
                  <div class="favorite-btn" @click.stop="toggleFavorite">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      :class="{ filled: isFavorite, outlined: !isFavorite }"
                    >
                      <path
                        d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
                        :fill="isFavorite ? '#FFD700' : 'none'"
                        :stroke="isFavorite ? '#FFD700' : '#FFFFFF'"
                        stroke-width="1.5"
                      />
                    </svg>
                  </div>
                </div>

                <div class="modal-input">
                  <div class="modal-label">Playback Speed</div>
                  <div class="speed-slider">
                    <input
                      type="range"
                      min="0.1"
                      max="2"
                      step="0.1"
                      v-model="playbackSpeed"
                      @input="updatePlaybackSpeed"
                    />
                    <span class="modal-label speed-value">
                      {{ formattedPlaybackSpeed }}x
                    </span>
                    <button class="speed-reset" @click="resetPlaybackSpeed">Reset</button>
                  </div>
                </div>

                <div class="modal-input">
                  <div class="modal-label">Delete Animation</div>
                  <button class="delete-btn" @click="showDeleteConfirm = true">
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="download-container">
          <div
            class="control-btn"
            :class="{ disabled: isLoading || hasError }"
            @click="handleDownloadIconClick"
          >
            <BaseIcon iconName="download"></BaseIcon>
            <div v-if="isDownloading" class="loading-indicator"></div>
          </div>

          <div v-if="showFormatDropdown" @click="closeDropdown">
            <div class="modal-window" @click.stop>
              <div v-if="isDownloading">
                <div class="downloading-animation">
                  <span class="dot"></span>
                  <span class="dot"></span>
                  <span class="dot"></span>
                </div>
                <p>Downloading...</p>
              </div>

              <div v-else>
                <div class="modal-label">Format</div>
                <div class="format-buttons">
                  <div class="format-btn" @click="handleDownload('glb')">GLB</div>
                  <div class="format-btn" @click="handleDownload('fbx')">FBX</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <div v-if="hasError" class="error-container">
      <div class="error-content">
        <BaseIcon iconName="alert" class="error-icon" />
        <h2 class="error-title">{{ getErrorTitle }}</h2>
        <p class="error-message">{{ getErrorMessage }}</p>
        <PrimaryBtn @click="handleRetry">Try Again</PrimaryBtn>
      </div>
    </div>

    <div v-else-if="isLoading || isProcessing" class="loading-container">
      <ProgressSpinner
        :progress="loadingProgress"
        :is-processing="isProcessing"
        :total-size="totalSize"
        :loaded-size="loadedSize"
        :show-size="!isProcessing"
        :label="isProcessing ? 'Processing animation...' : 'Loading animation...'"
      />
    </div>

    <div v-else class="model-viewer-container">
      <ThreeModelViewer ref="threeModelViewer" :model-buffer="modelBuffer" />
    </div>

    <!-- Debug panel -->
    <div v-if="store.getters.isDebugMode" class="debug-panel" ref="panel">
      <h3>Debug Panel</h3>
      <div class="debug-controls">
        <button @click="handleSimulateLoading">
          Toggle Loading ({{ isLoading ? "On" : "Off" }})
        </button>
        <button @click="handleSimulateError('SERVER_ERROR')">
          Simulate Server Error
        </button>
        <button @click="handleSimulateError('PARSE_ERROR')">Simulate Parse Error</button>
        <button @click="handleSimulateError('NETWORK_ERROR')">
          Simulate Network Error
        </button>
        <button @click="handleResetState">Reset State</button>
      </div>
    </div>

    <!-- Delete Confirmation Modal -->
    <div
      v-if="showDeleteConfirm"
      class="confirmation-overlay"
      @click="showDeleteConfirm = false"
    >
      <div class="confirmation-modal" @click.stop>
        <h3>Delete Animation</h3>
        <p>
          Are you sure you want to delete this animation? This action cannot be undone.
        </p>
        <div class="confirmation-actions">
          <button class="cancel-action" @click="showDeleteConfirm = false">Cancel</button>
          <button class="confirm-action" @click="handleDelete">Delete</button>
        </div>
      </div>
    </div>

    <!-- Success/Error Notification -->
    <transition name="notification">
      <div
        v-if="showNotification"
        class="notification"
        :class="isErrorNotification ? 'notification-error' : 'notification-success'"
      >
        <div class="notification-message">{{ notificationMessage }}</div>
        <button class="notification-close" @click="closeNotification">&times;</button>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { simulateLoading, simulateError, resetState } from "@/utils/debugHelpers";
import { animationAPI } from "@/services/api";

import ThreeModelViewer from "../components/ThreeModelViewer.vue";
import BaseIcon from "@/components/BaseIcon.vue";
import AnimationNameEditor from "@/components/AnimationNameEditor.vue";
import ProgressSpinner from "@/components/ProgressSpinner.vue";
import PrimaryBtn from "@/components/PrimaryBtn.vue";

const route = useRoute();
const router = useRouter();
const store = useStore();

const animationId = route.query.animationId;
const sessionId = store.getters.getSessionId;

const threeModelViewer = ref(null);

const showFormatDropdown = ref(false);
const showSettingsDropdown = ref(false);

const animationName = ref("");
const isFavorite = ref(false);
const promt = ref("");

const isDownloading = ref(false);
const downloadProgress = ref(0);

const isLoading = ref(false);

const playbackSpeed = ref(1);

const formattedPlaybackSpeed = computed(() => parseFloat(playbackSpeed.value).toFixed(1));

const hasError = ref(false);
const errorType = ref("");

const isProcessing = ref(false);
const loadingProgress = ref(0);
const totalSize = ref(0);
const loadedSize = ref(0);

const modelBuffer = ref(null);

const RETRY_INTERVAL = 5000;
let processInterval = null;

const showDeleteConfirm = ref(false);
const abortController = ref(null);

// Add notification refs
const showNotification = ref(false);
const notificationMessage = ref("");
const isErrorNotification = ref(false);
const notificationTimeout = ref(null);

const getErrorTitle = computed(() => {
  switch (errorType.value) {
    case "SERVER_ERROR":
      return "Server Error";
    case "PARSE_ERROR":
      return "Animation Load Error";
    case "NETWORK_ERROR":
      return "Connection Error";
    default:
      return "Error";
  }
});

const getErrorMessage = computed(() => {
  switch (errorType.value) {
    case "SERVER_ERROR":
      return "Our servers are having trouble processing your request. Please try again later.";
    case "PARSE_ERROR":
      return "We couldn't load this animation. The file might be corrupted.";
    case "NETWORK_ERROR":
      return "Please check your internet connection and try again.";
    default:
      return "Something went wrong. Please try again.";
  }
});

function updatePlaybackSpeed() {
  threeModelViewer.value.setPlaybackSpeed(playbackSpeed.value);
}

async function handleDownload(extension) {
  // isDownloading.value = true;
  downloadProgress.value = 0;

  try {
    const response = await animationAPI.downloadAnimation(
      animationId,
      sessionId,
      extension
    );
    if (!response.ok) throw new Error("Failed to download model file");

    isDownloading.value = true;

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${animationName.value || "animation"}.${extension}`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  } catch (error) {
    console.error("Error downloading file:", error);
    handleError(new Error("Failed to download model file"));
  } finally {
    isDownloading.value = false;
    showFormatDropdown.value = false;
  }
}

async function getAnimationInfo() {
  try {
    const data = await animationAPI.getAnimationDetails(sessionId, animationId, router);
    animationName.value = data.animation_name;
    isFavorite.value = data.is_favorite;
    promt.value = data.promt;
  } catch (error) {
    console.error("Error fetching animation info:", error);
  }
}

async function toggleFavorite() {
  try {
    const result = await animationAPI.toggleFavorite(sessionId, animationId);
    if (result.message) {
      isFavorite.value = !isFavorite.value;
    } else {
      console.error("Failed to update favorite status");
    }
  } catch (error) {
    console.error("Error setting favorite:", error);
  }
}

async function saveAnimationName(newName) {
  try {
    await animationAPI.changeAnimationName(sessionId, animationId, newName);
    showNotification.value = true;
    notificationMessage.value = "Animation name successfully updated";
    isErrorNotification.value = false;

    notificationTimeout.value = setTimeout(() => {
      showNotification.value = false;
    }, 3000);
  } catch (error) {
    console.error("Error updating animation name:", error);
    showNotification.value = true;
    notificationMessage.value =
      "Failed to update animation name: " + (error.message || "Unknown error");
    isErrorNotification.value = true;

    notificationTimeout.value = setTimeout(() => {
      showNotification.value = false;
    }, 4000);
  }
}

function handleDownloadIconClick() {
  if (isLoading.value) return;
  showFormatDropdown.value = !showFormatDropdown.value;
}

function handleOpenSettings() {
  if (isLoading.value) return;
  showSettingsDropdown.value = !showSettingsDropdown.value;
}

function handleHomeButton() {
  router.push("/app/home");
}

function closeDropdown() {
  if (isDownloading.value) return;
  showFormatDropdown.value = false;
}

function handleCloseSettings() {
  showSettingsDropdown.value = false;
}

function handleOutsideClick(event) {
  const settingsDropdown = document.querySelector(".settings-container");
  const formatDropdown = document.querySelector(".download-container");

  if (
    showSettingsDropdown.value &&
    settingsDropdown &&
    !settingsDropdown.contains(event.target)
  ) {
    showSettingsDropdown.value = false;
  }

  if (
    showFormatDropdown.value &&
    formatDropdown &&
    !formatDropdown.contains(event.target)
  ) {
    showFormatDropdown.value = false;
  }
}

function handleError(error) {
  hasError.value = true;
  isLoading.value = false;

  if (error.message.includes("Server error")) {
    errorType.value = "SERVER_ERROR";
  } else if (error.message.includes("parse")) {
    errorType.value = "PARSE_ERROR";
  } else if (error.message.includes("download")) {
    errorType.value = "NETWORK_ERROR";
  } else {
    errorType.value = "UNKNOWN";
  }
}

async function downloadModelChunks(response) {
  const contentLength = response.headers.get("content-length");
  totalSize.value = parseInt(contentLength, 10);

  if (!contentLength || totalSize.value === 0) {
    throw new Error("Server error: Empty response received");
  }

  let receivedLength = 0;
  const reader = response.body.getReader();
  const chunks = [];

  try {
    let result;
    while (!(result = await reader.read()).done) {
      chunks.push(result.value);
      receivedLength += result.value.length;
      loadedSize.value = receivedLength;
      loadingProgress.value = (receivedLength / totalSize.value) * 100;
    }

    if (receivedLength === 0 || chunks.length === 0) {
      throw new Error("Server error: No data received");
    }

    const allChunks = new Uint8Array(receivedLength);
    let position = 0;
    for (const chunk of chunks) {
      allChunks.set(chunk, position);
      position += chunk.length;
    }

    if (allChunks.buffer.byteLength === 0) {
      throw new Error("Server error: Empty file received");
    }

    return allChunks.buffer;
  } catch (error) {
    throw new Error("Failed to download model chunks: " + error.message);
  }
}

async function loadAnimation() {
  isProcessing.value = true;
  abortController.value = new AbortController();

  try {
    let isPolling = true;
    while (isPolling) {
      if (abortController.value.signal.aborted) {
        throw new Error("Processing aborted");
      }

      const response = await animationAPI.downloadAnimation(animationId, sessionId);

      if (!response.ok && response.status !== 206) {
        throw new Error(`Server error: ${response.status}`);
      }

      if (response.status === 206) {
        await new Promise((resolve) => setTimeout(resolve, RETRY_INTERVAL));
        continue;
      }

      isProcessing.value = false;
      isLoading.value = true;

      const buffer = await downloadModelChunks(response);

      if (!buffer || buffer.byteLength === 0) {
        throw new Error("Server error: Invalid or empty animation file");
      }

      modelBuffer.value = buffer;
      isPolling = false;
    }
  } catch (error) {
    console.error("Error loading animation:", error);
    handleError(error);
  } finally {
    isProcessing.value = false;
    isLoading.value = false;
    if (abortController.value) {
      abortController.value = null;
    }
  }
}

function handleRetry() {
  hasError.value = false;
  errorType.value = "";
  loadAnimation();
}

function handleSimulateLoading() {
  simulateLoading(isLoading, hasError, loadingProgress);
}

function handleSimulateError(type) {
  simulateError(hasError, isLoading, errorType, type);
}

function handleResetState() {
  resetState(hasError, isLoading, loadingProgress, errorType);
}

function resetPlaybackSpeed() {
  playbackSpeed.value = 1;
  updatePlaybackSpeed();
}

async function handleDelete() {
  try {
    await animationAPI.deleteAnimation(sessionId, animationId);
    showNotification.value = true;
    notificationMessage.value = "Animation successfully deleted";
    isErrorNotification.value = false;

    // Set timeout to redirect after showing success message
    setTimeout(() => {
      router.push("/app/animations");
    }, 1500);
  } catch (error) {
    console.error("Error deleting animation:", error);
    showNotification.value = true;
    notificationMessage.value =
      "Failed to delete animation: " + (error.message || "Unknown error");
    isErrorNotification.value = true;
  } finally {
    showDeleteConfirm.value = false;
  }
}

function closeNotification() {
  showNotification.value = false;
  if (notificationTimeout.value) {
    clearTimeout(notificationTimeout.value);
    notificationTimeout.value = null;
  }
}

onMounted(() => {
  document.addEventListener("click", handleOutsideClick);

  if (animationId) {
    getAnimationInfo();
  }

  loadAnimation();
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleOutsideClick);

  if (abortController.value) {
    abortController.value.abort();
    abortController.value = null;
  }

  if (processInterval) {
    clearInterval(processInterval);
    processInterval = null;
  }

  // Clear any remaining notification timeout
  if (notificationTimeout.value) {
    clearTimeout(notificationTimeout.value);
    notificationTimeout.value = null;
  }
});
</script>

<style scoped>
.viewer-wrapper {
  width: 100%;
  height: 100vh;
}

.anim-view-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10vh;
  position: relative;
  padding: 0 1rem;
  min-height: 60px;
}

.header-content {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: auto;
  white-space: nowrap;
  z-index: 1;
}

.logo {
  height: 50px;
  width: auto;
}

.header-1 {
  margin: 0;
  font-size: 1.5rem;
  line-height: 50px;
}

.control-btn {
  flex-shrink: 0;
  position: relative;
}

.header-controls {
  display: flex;
  gap: 0.5rem;
}

.settings-container,
.download-container {
  position: relative;
  display: inline-block;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
}

.settings-btn {
  margin-right: 0.5rem;
}

.locked {
  cursor: not-allowed;
}

.modal-window {
  position: absolute;
  top: 110%;
  left: 50%;
  transform: translateX(-90%);
  background-color: rgba(42, 41, 42, 1);
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  z-index: 999;
  min-width: 300px;
}

.modal-label {
  padding-bottom: 1rem;
  color: rgba(255, 255, 255, 0.4);
  text-align: left;
}

.format-buttons {
  display: flex;
}

.format-btn {
  padding: 0.4rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  cursor: pointer;
}

.format-btn:hover {
  background-color: rgba(255, 255, 255, 0.5);
  transition-duration: 0.4s;
}

.format-btn:not(:first-child) {
  margin-left: 0.7rem;
}

.animation-details {
  width: 25vw;
  min-width: 300px;
}

.favorite-btn {
  cursor: pointer;
  text-align: left;
  margin-bottom: 0.7rem;
}

.name,
.prompt {
  text-align: left;
}

.prompt {
  padding: 0.3rem;
  background-color: rgba(255, 2555, 255, 0.1);
  border-radius: 0.4rem;
}

.name-label {
  cursor: pointer;
}

.name {
  display: flex;
  align-items: center;
}

.name input {
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  width: 100%;
}

.progress-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.progress-circle {
  display: block;
}

.speed-slider {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  gap: 0.5rem;
  width: 100%;
}

.speed-slider input[type="range"] {
  flex: 1;
  min-width: 100px;
  -webkit-appearance: none;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  height: 4px;
}

.speed-slider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: linear-gradient(135deg, #20ad8b 0%, #ace41b 100%);
  cursor: pointer;
}

.speed-slider input[type="range"]::-moz-range-thumb {
  width: 16px;
  height: 16px;
  border: none;
  border-radius: 50%;
  background: linear-gradient(135deg, #20ad8b 0%, #ace41b 100%);
  cursor: pointer;
}

.speed-slider input[type="range"]::-ms-thumb {
  width: 16px;
  height: 16px;
  border: none;
  border-radius: 50%;
  background: linear-gradient(135deg, #20ad8b 0%, #ace41b 100%);
  cursor: pointer;
}

.speed-slider input[type="range"]:focus {
  outline: none;
}

.speed-value {
  padding: 0;
  min-width: 3ch;
  margin-right: 0.5rem;
  white-space: nowrap;
}

.speed-reset {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.4);
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.speed-reset:hover {
  color: white;
  background: rgba(255, 255, 255, 0.1);
}

.name-label {
  flex-grow: 1;
  color: white;
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.name input {
  flex-grow: 1;
  border: none;
  padding: 5px;
  background: transparent;
  color: white;
  outline: none;
  font-size: 1rem;
  width: 100%;
}

.edit-icon {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.7);
  transition: opacity 0.3s ease;
  flex-shrink: 0;
  margin-left: 8px;
}

.edit-icon:hover {
  opacity: 0.7;
}

.modal-input:not(:last-child) {
  margin-bottom: 1.7rem;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.error-container,
.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 30vh);
}

.error-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1rem;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  max-width: 400px;
}

.error-icon {
  font-size: 2rem;
  color: #ff4444;
}

.error-title {
  margin: 0;
  color: rgba(255, 255, 255, 0.9);
}

.error-message {
  margin: 0;
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.9rem;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.downloading-animation {
  display: flex;
  justify-content: center;
  gap: 6px;
  margin: 20px 0;
}

.dot {
  width: 8px;
  height: 8px;
  background: white;
  border-radius: 50%;
  animation: jump 0.8s ease-in-out infinite;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes jump {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

.loading-indicator {
  position: absolute;
  top: -2px;
  right: -2px;
  width: 8px;
  height: 8px;
  background-color: #ff4444;
  border-radius: 50%;
}

.debug-panel {
  position: fixed;
  background-color: rgba(42, 41, 42, 0.8);
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  z-index: 999;
  top: 0;
  right: 50%;
}

.debug-controls {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.debug-controls button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  background: #2c3e50;
  color: white;
  cursor: pointer;
}

.debug-controls button:hover {
  background: #34495e;
}

.model-viewer-container {
  box-sizing: border-box;
  padding: 0 0.5rem;
  width: 99%;
  height: calc(100vh - 10vh);
}

.delete-btn {
  background: none;
  border: none;
  color: #ff4444;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;
}

.delete-btn:hover {
  background: rgba(255, 68, 68, 0.1);
}

.confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.confirmation-modal {
  background: rgba(42, 41, 42, 1);
  padding: 24px;
  border-radius: 16px;
  width: 90%;
  max-width: 400px;
  text-align: center;
}

.confirmation-modal h3 {
  color: #ff4444;
  margin: 0 0 16px 0;
}

.confirmation-modal p {
  color: rgba(255, 255, 255, 0.7);
  margin: 0 0 24px 0;
  line-height: 1.5;
}

.confirmation-actions {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.cancel-action,
.confirm-action {
  padding: 8px 24px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;
}

.cancel-action {
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
}

.cancel-action:hover {
  background: rgba(255, 255, 255, 0.2);
}

.confirm-action {
  background: #ff4444;
  color: white;
}

.confirm-action:hover {
  background: #ff6666;
}

/* Notification */
.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 16px 24px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2000;
  max-width: 400px;
  animation: slide-in 0.3s ease-out forwards;
}

.notification-success {
  background: linear-gradient(135deg, #20ad8b 0%, #ace41b 100%);
  color: white;
}

.notification-error {
  background: linear-gradient(135deg, #ff4444 0%, #ff7676 100%);
  color: white;
}

.notification-message {
  margin-right: 16px;
  font-size: 14px;
}

.notification-close {
  cursor: pointer;
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  opacity: 0.8;
  transition: opacity 0.2s;
}

.notification-close:hover {
  opacity: 1;
}

@keyframes slide-in {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

.notification-exit {
  animation: slide-out 0.3s ease-in forwards;
}
</style>
