import { createStore } from 'vuex'
import Cookies from 'js-cookie'
import { userAPI } from '@/services/api'
import router from '@/router'

const store = createStore({
  state: {
    sessionId: Cookies.get('sessionId') || '',
    userFolderUrl: null,
    email: Cookies.get('email') || '',
    redirectMessage: null,
    activePlan: 'Freemium',
    selectedPlan: null,
    remainingCredits: 0,
    isDebugMode: process.env.VUE_APP_DEBUG === 'true',
    authError: null,
    userDetails: null,
    isAnnual: false,
    planDetails: null,
  },
  mutations: {
    setSessionId(state, sessionId) {
      state.sessionId = sessionId
      Cookies.set('sessionId', sessionId, { expires: 7 })
    },
    clearSessionId(state) {
      state.sessionId = ''
      Cookies.remove('sessionId')
    },
    setUserFolderUrl(state, userFolderUrl) {
      state.userFolderUrl = userFolderUrl
    },
    setEmail(state, email) {
      state.email = email
      Cookies.set('email', email, { expires: 7 })
    },
    setRedirectMessage(state, message) {
      state.redirectMessage = message
    },
    setSelectedPlan(state, plan) {
      state.selectedPlan = plan
    },
    setRemainingCredits(state, credits) {
      state.remainingCredits = credits
    },
    setAuthError(state, error) {
      state.authError = error
    },
    setUserDetails(state, details) {
      if (Array.isArray(details)) {
        state.userDetails = details[0] || null;
      } else if (typeof details === 'object' && details !== null) {
        state.userDetails = details;
      } else {
        state.userDetails = null;
      }
    },
    setActivePlan(state, plan) {
      state.activePlan = plan
    },
    setIsAnnual(state, value) {
      state.isAnnual = value;
    },
    setPlanDetails(state, details) {
      state.planDetails = details;
    },
  },
  actions: {
    async login({ commit, dispatch }, { sessionId, userFolderUrl, email }) {
      commit('setSessionId', sessionId)
      commit('setUserFolderUrl', userFolderUrl)
      commit('setEmail', email)
      await dispatch('fetchRemainingCredits')
    },
    logout({ commit }) {
      commit('clearSessionId')
      commit('setUserFolderUrl', null)
      commit('setEmail', '')
      Cookies.remove('email')
      router.push('/login')
    },
    setRedirectMessage({ commit }, message) {
      commit('setRedirectMessage', message)
    },
    async fetchRemainingCredits({ state, commit, dispatch }) {
      if (!state.sessionId) return
      try {
        const data = await userAPI.getRemainingCredits(state.sessionId)
        const credits = data?.remaining_time ?? 0
        commit('setRemainingCredits', credits)
      } catch (error) {
        console.error('Failed to fetch remaining credits:', error)
        commit('setAuthError', error.message)
        if (error.message === 'Authentication failed') {
          dispatch('logout')
        }
      }
    },
    async fetchUserDetails({ state, commit, dispatch }) {
      if (!state.sessionId) return;
      try {
        const response = await userAPI.getUserDetails(state.sessionId);
        if (response) {
          commit('setUserDetails', response);
          const plan = Array.isArray(response) ? 
            response[0]?.current_subscription_plan : 
            response.current_subscription_plan;
          commit('setActivePlan', plan || 'Freemium');
        } else {
          console.error('No user details received:', response);
          commit('setUserDetails', null);
        }
      } catch (error) {
        console.error('Failed to fetch user details:', error);
        commit('setAuthError', error.message);
        if (error.status === 401) {
          dispatch('logout');
        }
      }
    },
    updateIsAnnual({ commit }, value) {
      commit('setIsAnnual', value);
    },
    async fetchPlanDetails({ commit }) {
      try {
        const planDetails = await userAPI.getPlanDetails();
        commit('setPlanDetails', planDetails);
        return planDetails;
      } catch (error) {
        console.error('Failed to fetch plan details:', error);
        return null;
      }
    },
  },
  getters: {
    isLoggedIn: state => state.sessionId !== '',
    getSessionId: state => state.sessionId,
    getUserFolderUrl: state => state.userFolderUrl,
    getEmail: state => state.email,
    getRedirectMessage: state => state.redirectMessage,
    getRemainingCredits: state => state.remainingCredits,
    isDebugMode: state => state.isDebugMode,
    getAuthError: state => state.authError,
    getUserDetails: state => state.userDetails,
    getPlanDetails: state => state.planDetails
  }
})

export default store