<template>
  <div v-if="uploadProgress === -1" class="prompt-wrapper">
    <h1 class="header-1">Create a unique 3D animation by prompt</h1>

    <div class="caption input-label">Animation Name</div>
    <base-input
      class="animation-name-input"
      type="text"
      placeholder="Animation Name"
      v-model="animationName"
    />

    <div class="input-form">
      <textarea
        v-model="prompt"
        class="prompt-text"
        :class="{ 'error-border': characterCount > 255 }"
        placeholder="Write your prompt here..."
        @input="updateCharacterCount"
        rows="5"
      ></textarea>

      <div class="input-form-bottom">
        <SecondBtn
          class="action-btn"
          :disabled="characterCount === 0 || !animationName"
          @click="proccessPrompt"
        >
          Create animation
        </SecondBtn>

        <div class="caption" :class="{ 'error-text': characterCount > 255 }">
          {{ characterCount }} / 255
        </div>
      </div>
    </div>

    <div class="caption input-label">Seconds</div>
    <base-input
      class="seconds-input"
      type="number"
      placeholder="Seconds"
      :modelValue="String(seconds)"
      @update:modelValue="(val) => (seconds = Number(val))"
    />

    <div class="caption input-label">Prompt Examples</div>
    <div class="prompt-examples caption">
      <div class="example-list">
        <div v-for="(example, index) in promptExamples" :key="index" class="example-item">
          <div class="example-text">{{ example }}</div>
          <button class="copy-btn" @click="copyExample(example)">
            <span class="copy-icon">⎘</span>
          </button>
        </div>
      </div>
      <div class="examples-note">
        At the moment, only one preloaded 3D model is available for animation. We are
        already working on the feature for other 3D models.
      </div>
    </div>
  </div>

  <div v-else class="progress-container">
    <ProgressSpinner
      :progress="uploadProgress"
      :is-processing="true"
      :label="'Processing animation...'"
    />
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import BaseInput from "@/components/BaseInput.vue";
import SecondBtn from "@/components/SecondBtn.vue";
import ProgressSpinner from "@/components/ProgressSpinner.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { animationAPI } from "@/services/api";

const store = useStore();
const router = useRouter();

const prompt = ref("");
const seconds = ref(5);
const animationName = ref("");
const characterCount = ref(0);

const uploadProgress = ref(-1);
const promptExamples = [
  "A person was pushed but didn't fail",
  "A person makes two steps forward and one backward",
  "A person picks ups an object from the ground",
  "A person is dancing salsa",
];

function copyExample(exampleText) {
  prompt.value = exampleText;
  updateCharacterCount();
}

function updateCharacterCount() {
  characterCount.value = prompt.value.length;
}

onMounted(() => {
  const now = new Date();
  const dateStr = now.toISOString().split("T")[0];
  const timeStr = now.toTimeString().split(" ")[0];
  animationName.value = `${dateStr} ${timeStr}`;
});

async function proccessPrompt() {
  if (characterCount.value >= 255) {
    alert("Prompt exceeds the maximum character limit.");
    return;
  }

  if (!animationName.value) {
    alert("Animation name is required.");
    return;
  }

  const sessionId = store.getters.getSessionId;
  const promptData = {
    prompt: prompt.value,
    duration: seconds.value,
    fps: 30,
    temperature: 0.7,
    name: animationName.value,
  };

  try {
    uploadProgress.value = 0;
    simulateProcessing();
    const result = await animationAPI.createPromptAnimation(promptData, sessionId);
    if (result.hashed_id) {
      router.push({ path: "/animation", query: { animationId: result.hashed_id } });
    } else {
      throw new Error("No hashed_id in response");
    }
  } catch (error) {
    console.error("Error processing prompt:", error);
    alert("Error processing prompt: " + (error.message || "Unknown error"));
    resetUploadState();
  }
}

function simulateProcessing() {
  const totalTime = 10000;
  const intervalTime = 100;
  const increments = totalTime / intervalTime;
  let currentProgress = 0;

  const interval = setInterval(() => {
    currentProgress += 100 / increments;
    if (currentProgress >= 99) {
      clearInterval(interval);
      uploadProgress.value = 99;
    } else {
      uploadProgress.value = currentProgress;
    }
  }, intervalTime);
}

function resetUploadState() {
  uploadProgress.value = -1;
}
</script>

<style scoped>
.header-1 {
  text-align: center;
}

.prompt-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 60%;
}

.input-form,
.prompt-description {
  width: 100%;
  padding: 1rem;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.1);
}

.prompt-text {
  width: 100%;
  background: transparent;
  border: none;
  color: #fff;
}

.prompt-text:focus {
  outline: none;
  border: none;
}

.prompt-text.error-border {
  border: 1px solid red;
}

.input-form-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.action-btn {
  padding: 9px 16px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.4);
  cursor: pointer;
  border: none;
}

.action-btn:disabled {
  background: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.2);
  cursor: not-allowed;
}

.caption {
  color: rgba(255, 255, 255, 0.4);
}

.caption.error-text {
  color: red;
}

.seconds-input,
.animation-name-input {
  width: 100%;
}

.prompt-description {
  width: 100%;
  padding: 1rem;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.1);
}

.prompt-examples {
  width: 100%;
  padding: 1rem;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.1);
}

.examples-title {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: rgba(255, 255, 255, 0.7);
}

.input-label {
  width: 100%;
  text-align: left;
  margin-top: 1rem;
}

.example-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.example-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
}

.example-text {
  flex-grow: 1;
  color: rgba(255, 255, 255, 0.7);
}

.copy-btn {
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  font-size: 1rem;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copy-btn:hover {
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.8);
}

.copy-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}

.examples-note {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.4);
}

.progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 2rem;
}
</style>
