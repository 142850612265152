<template>
  <div class="plan-summary">
    <div class="plan-card">
      <div class="plan-card__info">
        <p class="plan-card__title">{{ planData.title }}</p>
        <p class="plan-card__description caption">{{ planData.description }}</p>
      </div>

      <ul class="plan-card__features caption" v-if="planData.features?.length">
        <li
          class="plan-card__feature-item"
          v-for="(feature, index) in planData.features"
          :key="index"
        >
          {{ feature }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { plans } from "@/constants/plans";
import { useStore } from "vuex";

const store = useStore();
const apiPlans = computed(() => store.getters.getPlanDetails);

const props = defineProps({
  planName: {
    type: String,
    required: true,
  },
  status: {
    type: String,
    required: true,
  },
  subscriptionId: {
    type: String,
    default: null,
  },
  expiryDate: {
    type: String,
    default: null,
  },
  isAnnual: {
    type: Boolean,
    default: false,
  },
});

const planData = computed(() => {
  // Check if we have API plans
  if (apiPlans.value) {
    // Check if the plan exists in the API data
    for (const [key, plan] of Object.entries(apiPlans.value)) {
      if (
        props.planName === key ||
        (props.planName === "Basic" && key === "Basic") ||
        (props.planName === "Pro" && key === "Pro") ||
        (props.planName === "Freemium" && key === "Freemium") ||
        (props.planName === "Enterprise" && key === "Enterprise")
      ) {
        return {
          title: plan.title,
          description: plan.description,
          features: plan.features,
        };
      }
    }
  }

  // Fallback to hardcoded plans if not found in API data
  return plans[props.planName] || plans.Freemium;
});
</script>

<style scoped>
.plan-summary {
  width: 100%;
  max-width: 400px;
}

.plan-card__info {
  margin-bottom: 20px;
}

.plan-card__title {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.2em;
  margin-bottom: 8px;
}

.plan-card__description {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 16px;
  font-size: 0.9rem;
}

.plan-card__features {
  list-style: disc;
  padding: 0 0 0 20px;
  margin: 20px 0;
}

.plan-card__feature-item {
  text-align: left;
  margin: 8px 0;
  color: rgba(255, 255, 255, 0.7);
}

.status-container {
  margin-bottom: 20px;
}

.status {
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 0.9em;
  font-weight: 500;
}

.status-active {
  background: linear-gradient(135deg, #20ad8b 0%, #ace41b 100%);
  color: white;
}

.status-inactive {
  background: rgba(255, 68, 68, 0.1);
  color: #ff4444;
}

.status-free {
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
}

.plan-meta {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.4);
}

.plan-meta p {
  margin: 4px 0;
}

.meta-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
}

.meta-label {
  color: rgba(255, 255, 255, 0.4);
}

.meta-value {
  color: rgba(255, 255, 255, 0.7);
}
</style>
