<template>
  <div v-if="isDebugMode" class="debug-controls">
    <h3 style="margin-bottom: 10px;">Debug Controls</h3>
    <div style="display: flex; gap: 10px;">
      <SecondBtn @click="showInitialState">Initial State</SecondBtn>
      <SecondBtn @click="showTimelineState">Timeline State</SecondBtn>
      <SecondBtn @click="showUploadingState">Uploading State (0%)</SecondBtn>
      <SecondBtn @click="showUploadingProgress">Uploading Progress (45%)</SecondBtn>
      <SecondBtn @click="showProcessingState">Processing State</SecondBtn>
    </div>
  </div>

  <div class="upload-container">
    <div v-if="uploadProgress === -1 && !showTimeline" class="drop-file-area">
      <div class="content-area" @dragover.prevent="handleDragOver" @dragenter.prevent="handleDragEnter"
        @dragleave.prevent="handleDragLeave" @drop.prevent="handleFileDrop" :class="{ highlight: isDraggingOver }">
        <div>Drag and drop a video</div>
        <p class="caption">File size can be up to 50MB</p>

        <SecondBtn @click="triggerFileInput">
          Or select a file
        </SecondBtn>

        <input type="file" ref="fileInput" @change="handleFileChange" style="display: none"
          accept=".mp4,.avi,.mov,video/mp4,video/x-msvideo,video/quicktime" />
      </div>
    </div>

    <div class="content-wrapper" v-else-if="uploadProgress === -1 && showTimeline">
      <TimeLineEditor :videoFile="selectedFile" @cancel="resetState" @submit-trim="uploadFileWithTrim" />
    </div>

    <ProgressSpinner v-else :progress="uploadProgress" :loaded-size="loadedSize" :total-size="totalSize"
      :show-size="true" :is-processing="isProcessing"
      label="Please wait while the video is uploading and being processed" />
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import SecondBtn from "@/components/SecondBtn.vue";
import TimeLineEditor from "@/components/TimeLineEditor.vue";
import ProgressSpinner from "@/components/ProgressSpinner.vue";
import { animationAPI } from '@/services/api';

const store = useStore();
const router = useRouter();

const isDebugMode = computed(() => store.getters.isDebugMode);

const uploadProgress = ref(-1);
const loadedSize = ref(0);
const totalSize = ref(0);
const isUploading = ref(false);
const isDraggingOver = ref(false);
const fileInput = ref(null);

const showTimeline = ref(false);
const selectedFile = ref(null);

const isProcessing = ref(false);

function handleDragOver(e) {
  e.preventDefault();
}
function handleDragEnter(e) {
  e.preventDefault();
  isDraggingOver.value = true;
}
function handleDragLeave(e) {
  e.preventDefault();
  isDraggingOver.value = false;
}
function triggerFileInput() {
  fileInput.value.click();
}
function handleFileChange(e) {
  const files = e.target.files;
  if (files.length) validateAndPrepareFile(files[0]);
}
function handleFileDrop(e) {
  e.preventDefault();
  const files = e.dataTransfer.files;
  if (files.length) validateAndPrepareFile(files[0]);
}

function validateAndPrepareFile(file) {
  const validTypes = ["video/mp4", "video/x-msvideo", "video/quicktime"];
  const validExts = [".mp4", ".avi", ".mov"];

  const lower = file.name.toLowerCase();
  const isValidType = validTypes.includes(file.type);
  const isValidExt = validExts.some((ext) => lower.endsWith(ext));

  if ((isValidType || isValidExt) && file.size <= 52428800) {
    selectedFile.value = file;
    showTimeline.value = true;
    isDraggingOver.value = false;
  } else {
    isDraggingOver.value = false;
    alert("Please upload MP4, AVI, or MOV file up to 50MB.");
  }
}

async function uploadFileWithTrim({ start_time, end_time, foot_lock, hands, face, multi_person, name }) {
    if (!selectedFile.value) return;

    isUploading.value = true;
    uploadProgress.value = 0;
    loadedSize.value = 0;
    totalSize.value = selectedFile.value.size;

    const formData = new FormData();
    formData.append("video_file", selectedFile.value);
    formData.append("start_seconds", start_time);
    formData.append("end_seconds", end_time);
    formData.append("foot_lock", foot_lock);
    formData.append("hands", hands);
    formData.append("face", face);
    formData.append("multi_person", multi_person);
    formData.append("name", name);

    const sessionId = store.getters.getSessionId;

    try {
        const response = await animationAPI.uploadVideo(formData, sessionId, (loaded, total) => {
            loadedSize.value = loaded;
            totalSize.value = total;
            uploadProgress.value = (loaded / total) * 100;
        });

        isUploading.value = false;
        router.push({ path: "/animation", query: { animationId: response.hashed_id } });
    } catch (error) {
        alert("Failed to upload file");
        resetState();
    }
}

function resetState() {
  uploadProgress.value = -1;
  loadedSize.value = 0;
  totalSize.value = 0;
  isUploading.value = false;
  isDraggingOver.value = false;
  showTimeline.value = false;
  selectedFile.value = null;
}

function showInitialState() {
  uploadProgress.value = -1;
  showTimeline.value = false;
  isUploading.value = false;
  isProcessing.value = false;
  loadedSize.value = 0;
  totalSize.value = 0;
}

function showTimelineState() {
  uploadProgress.value = -1;
  showTimeline.value = true;
  isUploading.value = false;
  isProcessing.value = false;
}

function showUploadingState() {
  uploadProgress.value = 0;
  showTimeline.value = false;
  isUploading.value = true;
  isProcessing.value = false;
  loadedSize.value = 0;
  totalSize.value = 1024 * 1024 * 10; // 10MB sample file
}

function showUploadingProgress() {
  uploadProgress.value = 45;
  showTimeline.value = false;
  isUploading.value = true;
  isProcessing.value = false;
  loadedSize.value = 1024 * 1024 * 4.5; // 4.5MB loaded
  totalSize.value = 1024 * 1024 * 10; // 10MB sample file
}

function showProcessingState() {
  uploadProgress.value = 100;
  showTimeline.value = false;
  isUploading.value = true;
  isProcessing.value = true;
  loadedSize.value = 1024 * 1024 * 10; // 10MB loaded
  totalSize.value = 1024 * 1024 * 10; // 10MB sample file
}
</script>

<style scoped>
.upload-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 50vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

.drop-file-area {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 30vh;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px;
  text-align: center;
  margin: 20px;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

.content-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 70%;
  border: 2px dashed rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 8px;
}

.content-wrapper {
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.1);
  width: 70%;
}

.caption {
  color: rgba(255, 255, 255, 0.4);
}

.highlight {
  border-color: grey;
  background-color: rgba(255, 255, 255, 0.2);
}

.progress-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.progress-placeholder {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 12px;
}

.progress-circle-bg,
.progress-circle-fg {
  transition: stroke-dashoffset 0.3s linear;
}

.header-2 {
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

/* Debug styles */
.debug-controls {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  max-width: 400px;
}

.debug-controls h3 {
  margin: 0 0 10px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 14px;
}

.debug-controls>div {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.debug-controls :deep(button) {
  font-size: 12px;
  padding: 6px 12px;
}
</style>
